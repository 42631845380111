import { IconButton } from '@mui/material';
import React, { useState } from 'react'
import { addBrandAPICall, deleteBrandAPICall } from '../../../../../../API/Product Master/ProductMasterAPI';
import { useSelector } from 'react-redux';

const BrandCreationPopup = ({onClose}) => {

  const allBrandList = useSelector((state) => state.readymadeBrandSlice.value);
  const [inputValue,setInputValue] =useState("")

  const addBtnClick=()=>{
    addBrandAPICall({ brandName: inputValue },()=>setInputValue(""));
  }
  return (
      <div className="add-popup-container">
          <p>Add Brand</p>
          <div className="add-popup-input-container">
              <input
                  placeholder={"Brand name"}
                  value={inputValue}
                  onChange={(e) =>setInputValue(e.target.value)}
                  onKeyDown={(e) => {
                      e.key === "Enter" && addBtnClick();
                  }}
              />
              <button
                  disabled={inputValue !== "" ? false : true}
                  onClick={addBtnClick}
                  className={inputValue !== "" ? "" : "disabled"}
              >
                  +
              </button>
          </div>
          <div className="add-popup-table">
              <table>
                  <thead>
                      <tr>
                          <th>No</th>
                          <th>Name</th>
                          <th>Delete</th>
                      </tr>
                  </thead>
                  <tbody>
                      {allBrandList
                          ?.map((brand, i) => (
                              <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>{brand?.brandName}</td>
                                  <td>
                                      <IconButton onClick={()=>deleteBrandAPICall({brandId:brand?._id})}>
                                          <i class="bi bi-trash3 delete-icon"></i>
                                      </IconButton>
                                  </td>
                              </tr>
                          ))}
                  </tbody>
              </table>
          </div>
          <div className="add-popup-button-container">
              <button onClick={onClose} className="btn btn-secondary">
                  Close
              </button>
          </div>
          
      </div>
  )
}

export default BrandCreationPopup