import { Autocomplete, IconButton, TextField, Tooltip } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import logoImage from "../../../../../Assets/Images/Icon ionic-logo-buffer.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { vieWareHouseDestinationLocAPICall, vieWareHouseLocationAPICall } from "../../Configuration/wareHouseLocationAPI";
import { wareHouseListAPICall } from "../../Configuration/wareHouseAPI";
import { today } from "../../../../../Js/Date";
import { getAllNewProductList } from "../../../Accounts/Vendor/API/vendorBillAPI";
import { viewAllAllowedBranchesAPICall } from "../../../../../API/Settings/Company Settings/companySettingsAPI";
import SuccessSnackbar from "../../../../Single Components/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../Single Components/SnackBars/ErrorSnackbar";
import {
  EditInternalTransferAPICall,
  addInternalTransferAPICall,
  generateInternalTransferNameAPICall,
  internalTransferSingleViewAPICall,
  postInternalTransferAPICall,
} from "./InternalTransferAPI";
import { filterObjFromList } from "../../../../../Js/generalFunctions";
import postRibbon from "../../../../../Assets/statusRibbon/post.png";
import draftRibbon from "../../../../../Assets/statusRibbon/draft.png";
import { LoadingForm } from "../../../../Single Components/LoadingForm";
import { productSearchApi } from "../../../../../API/Purchase Manager/RFQ/ConfirmRfqApiCall";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
import { getLocationProductListApi } from "../BranchTransfer/BranchTransferAPI";

export const CreateInternalTransfer = () => {
  let navigate = useNavigate();
  const containerRef = useRef(null);
  const singleRowId = localStorage.getItem("singleViewById");

  const inventorySettingsConfigurationList = useSelector((state) => state.inventoryConfigSettingSlice.apiResList);
  
  // ware house list
  const wareHouseList = useSelector((state) => state.wareHouseSlice.value);
  // ware house location
  const wareHouseLocationList = useSelector(
    (state) => state.wareHouseLocationSlice.value
  );
  const destinationLocationList = useSelector(
    (state) => state.wareHouseLocationSlice.destinationLocations
  );
  // all allowed branches list
  const allowedBranchList = useSelector(
    (state) => state.allAllowedBranchesSlice.value
  );
  const singleView = useSelector(
    (state) => state.internalTransferSlice.singleValue
  );
  // new produuct list
  // const allNewProductList = useSelector(
  //   (state) => state.vendorBillSlice.NewProductList
  // );
  // login response
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  // name
  const generatedName = useSelector(
    (state) => state.internalTransferSlice.name
  );

  const { branchDetails } = useSelector((state) => state.mainDashBoardSlice);

  // new produuct list
  let allNewProductListRedux = useSelector(
    (state) => state.branchTransferSlice.productListData
  );
  // console.log(allNewProductListRedux,"...allNewProductListRedux")
  const dispatch = useDispatch();
  const addRowData = [{ value: "Add Header" }, { value: "Add Line" }];
  // console.log(singleView, "...singleView");
  const [loadingForm, setLoadingForm] = useState(false);

  const [logView, setLogView] = useState(false);

  const tableDataInitialForm={
    type: "lines",
    id: Date.now(),
    product: null,
    uom: "",
    onHandQty: "",
    transferQty: "",
  }
  const [tableData, setTableData] = useState([tableDataInitialForm]);
  // console.log(tableData, "......tableDatatableData");
  const [addRowInput, setAddRowInput] = useState(addRowData[1]);
  const internalTransferInitialState = {
    branch: branchDetails,
    wareHouse: null,
    sourceLoc: null,
    destiLoc: null,
    date: today,
    reference: "",
    contactPerson: "",
    isEdit: false,
    status: "",
  };
  const [internalTransferForm, setInternalTransferForm] = useState(
    internalTransferInitialState
  );
  const [wareHouseArray, setWareHouseArray] = useState([]);
  // const [locationArray, setlocationArray] = useState([]);
  const [destinationLocArray, setDestinationLocArray] = useState([]);
  const [tableDataForBackend, setTableDataForBackend] = useState([]);
  const [validationAlert, setValidationAlert] = useState({});
  const [sourceLoc, setSourceLoc] = useState([]);
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false,
  });
  const [editClicked, setEditClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [editDataId, setEditDataId] = useState("");
  const [allNewProductList, setAllNewProductList] = useState([]);

  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };
  const [addRowScroll, setAddRowScroll] = useState(false);
  const addRow = () => {
    if (addRowInput?.value === "Add Header") {
      setTableData([
        ...tableData,
        { type: "header", id: Date.now(), HeaderValue: "" },
      ]);
    } else if (addRowInput?.value === "Add Line") {
      setTableData([
        ...tableData,
        {
          type: "lines",
          id: Date.now(),
          product: null,
          uom: "",
          onHandQty: "",
          transferQty: "",
        },
      ]);
    }
    setAddRowScroll(!addRowScroll);
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [addRowScroll]);

  // const handleInputChange = (id, field) => (e, newValue) => {
  //   const { value } = e.target;
  //   const numericValue = parseFloat(value);
  //   const updatedData = tableData.map((row, index) => {
  //     if (index === id) {
  //       if (field === "product") {
  //         return {
  //           ...row,
  //           [field]: newValue,
  //           onHandQty: newValue?.stock||"",
  //           uom: newValue?.uomName||"",
  //           prodId: newValue?._id,
  //         };
  //       }

  //          // Validate if transferQty exceeds onHandQty
  //          if (field === "transferQty" && numericValue === 0) {
  //           setSnackBarStates({
  //             success: false,
  //             error: true,
  //             alert: true,
  //             message: "Transfer quantity cannot be zero!",
  //           });
  //           return row; // Keep row unchanged
  //         }

  //         // Validate if transferQty exceeds onHandQty
  //         if (field === "transferQty" && numericValue > row.onHandQty) {
  //           setSnackBarStates({
  //             success: false,
  //             error: true,
  //             alert: true,
  //             message: "Transfer quantity cannot exceed on-hand quantity!",
  //           });
  //           return row; // Keep row unchanged
  //         }
  //       return { ...row, [field]: value === 0 ? newValue : e.target.value };
  //     }
  //     return row;
  //   });
  //   setTableData(updatedData);
  // };
  const handleInputChange = (id, field) => (e, newValue) => {
    const { value } = e?.target || {};
    const numericValue = parseFloat(value);

    const updatedData = tableData.map((row, index) => {
      if (index === id) {
        if (field === "product") {
          // Clear other fields if product is cleared
          if (!newValue) {
            return {
              ...row,
              product: null,
              uom: "",
              onHandQty: "",
              transferQty: "",
            };
          }
          return {
            ...row,
            [field]: newValue,
            onHandQty: newValue?.stock || "",
            uom: newValue?.uomName || "",
            prodId: newValue?._id,
          };
        }

        // Validate transfer quantity
        if (field === "transferQty") {
          if (numericValue <= 0) {
            setSnackBarStates({
              success: false,
              error: true,
              alert: true,
              message: "Transfer quantity cannot be zero or negative!",
            });
            return row; 
          }

          if (numericValue > row.onHandQty) {
            setSnackBarStates({
              success: false,
              error: true,
              alert: true,
              message: "Transfer quantity cannot exceed on-hand quantity!",
            });
            return row; 
          }

          return { ...row, [field]: numericValue };
        }

        return { ...row, [field]: value };
      }
      return row;
    });

    setTableData(updatedData);
  };

  const handleDelete = (index) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(index, 1);
    setTableData(updatedTableData);
  };
  const clickLogView = () => {
    setLogView(!logView);
  };
  const backButtonFn = () => {
    if (internalTransferForm?.isEdit === false) {
      const userConfirmed = window.confirm(
        "Changes you made may not be saved."
      );

      if (userConfirmed) {
        navigate("/userdashboard/inventory/operation/Interanltransfer");
      }
    } else {
      navigate("/userdashboard/inventory/operation/Interanltransfer");
    }
  };

  useEffect(() => {
    viewAllAllowedBranchesAPICall({ employeeId: loginResponse?.data?._id });
    // productSearchApi({search:""})
    singleRowId && internalTransferSingleViewAPICall({ id: singleRowId });
  }, []);
  // default branch
  useEffect(() => {
    if (singleView === undefined) {
      setInternalTransferForm({
        ...internalTransferForm,
        branch: branchDetails,
      });
      generateInternalTransferNameAPICall({
        sourcebranchId: branchDetails?._id,
      });
      wareHouseListAPICall({
        branchId: branchDetails?._id,
      });
    }
  }, [branchDetails]);

  useEffect(() => {
    if (internalTransferForm?.wareHouse !== null) {
      vieWareHouseLocationAPICall({
        branchId: internalTransferForm?.branch?._id,
        warehouseId: internalTransferForm?.wareHouse?._id,
      });

      inventorySettingsConfigurationList?.multiWarehouse && vieWareHouseDestinationLocAPICall({branchId: internalTransferForm?.branch?._id});
    }
  }, [internalTransferForm?.wareHouse]);

  // filter ware house
  useEffect(() => {
    if (wareHouseList !== undefined && internalTransferForm?.branch !== null) {
      setWareHouseArray(wareHouseList);
    } else {
      setWareHouseArray([]);
    }
  }, [wareHouseList, internalTransferForm?.branch]);

  // filter location
  useEffect(() => {
    if (wareHouseLocationList !== null) {
      setSourceLoc(wareHouseLocationList);
    }
    // else {
    //   setSourceLoc([]);
    // }
  }, [internalTransferForm?.wareHouse, wareHouseLocationList]);
  // useEffect(() => {
  //   if (internalTransferForm?.wareHouse !== null) {
  //     setlocationArray(wareHouseLocationList);
  //   } else {
  //     setlocationArray([]);
  //   }
  // }, [internalTransferForm?.wareHouse, wareHouseLocationList]);

  // filter destination location with source location
  useEffect(() => {
    if (sourceLoc?.length !== 0 || internalTransferForm?.sourceLoc !== null) {
      let destinationList=inventorySettingsConfigurationList?.multiWarehouse?destinationLocationList:sourceLoc
      let data = destinationList?.filter((obj) => {
        if (obj._id !== internalTransferForm?.sourceLoc?._id) {
          return obj;
        }
      });
      setDestinationLocArray(data);
    }
  }, [sourceLoc, internalTransferForm?.sourceLoc]);
  // console.log(wareHouseLocationList,"...wareHouseLocationList")
  const getInternalTransForm = (key) => (e, newValue) => {
    if (key === "branch") {
      setInternalTransferForm({
        ...internalTransferForm,
        branch: newValue,
      });

      generateInternalTransferNameAPICall({ sourcebranchId: newValue?._id });
      wareHouseListAPICall({
        branchId: newValue?._id,
      });
    }
    if (key === "wareHouse") {
      setInternalTransferForm({
        ...internalTransferForm,
        wareHouse: newValue,
      });
    }
    if (key === "sourceLoc") {
      setTableData([tableDataInitialForm])

      setInternalTransferForm({
        ...internalTransferForm,
        sourceLoc: newValue,
      });

    }
    if (key === "destiLoc") {
      setInternalTransferForm({
        ...internalTransferForm,
        destiLoc: newValue,
      });
    }
    if (key === "date") {
      setInternalTransferForm({
        ...internalTransferForm,
        date: newValue,
      });
    }
    if (key === "reference") {
      setInternalTransferForm({
        ...internalTransferForm,
        reference: e.target.value,
      });
    }
    if (key === "contactPerson") {
      setInternalTransferForm({
        ...internalTransferForm,
        contactPerson: e.target.value,
      });
    }
  };

  // set table for backend
  useEffect(() => {
    setTableDataForBackend(
      tableData
        ?.map((r) => {
          if (r?.type === "header") {
            return {
              line_type: r?.type,
              label: r?.HeaderValue,
            };
          } else {
            if (r._id) {
              return {
                line_type: r?.type,
                _id: r?.prodId,
                mainCatgeoryId: r?.product?.mainCatgeoryId,
                product: r?.product?.productName,
                prodType: "goods",
                label: "",
                uomName: r?.uomName,
                uom: r?.product?.uom,
                purchaseUomName: "",
                purchaseuom: "",
                qty: r?.product?.stock,
                unitPrice: r?.product?.cost,
                cost: "",
                spMargin: 0,
                onHandQty: r?.onHandQty,
                transferQty: r?.transferQty,
                recievedQty: 0,
                poscat: r?.product?.poscat,
              };
            } else {
              return null;
            }
          }
        })
        .filter((obj) => obj !== null)
    );
  }, [tableData]);

  // draft function

  const formData = new FormData();
  formData.append("branchId", internalTransferForm?.branch?._id);
  formData.append("warehouseId", internalTransferForm?.wareHouse?._id);
  formData.append("source", internalTransferForm?.sourceLoc?._id);
  formData.append("destination", internalTransferForm?.destiLoc?._id);
  formData.append("date", internalTransferForm?.date);
  formData.append("reference", internalTransferForm?.reference);
  formData.append("contactPerson", internalTransferForm?.contactPerson);
  const productInfo = tableData
    ?.filter((obj) => obj.product !== null)
    .map((item) => {
      if (item.type === "header") {
        return {
          line_type: item.type,
          label: item?.value,
        };
      } else {
        return {
          line_type: item.type,
          _id: item.prodId,
          mainCatgeoryId: item?.product?.mainCatgeoryId,
          // product: item?.product,
          product: item?.product?.productName,
          prodType: "goods",
          label: "",
          uomName: item?.product?.uomName,
          uom: item?.product?.uom,
          purchaseUomName: "",
          purchaseuom: "",
          unitPrice: item?.product?.cost,
          cost: "",
          spMargin: 0,
          onHandQty: item?.onHandQty,
          transferQty: item?.transferQty,
          recievedQty: 0,
          poscat: item?.product?.poscat,
        };
      }
    });

  formData.append("productInfo", JSON.stringify(productInfo));
  // formData.append("productInfo", JSON.stringify(tableDataForBackend));

  const saveInternalTransFun = () => {
    const hasNullProduct = tableData.some(
      (row) => row.type === "lines" && row.product === null
    );
    const hasEmptyTransferQty = tableData.some(
      (row) => row.type === "lines" && !row.transferQty
    );

    dispatch(setFilterActive(false));
    if (internalTransferForm?.branch === null) {
      setValidationAlert({ ...validationAlert, branch: "fill this field" });
    } else if (internalTransferForm?.wareHouse === null) {
      setValidationAlert({ ...validationAlert, wareHouse: "fill this field" });
    } else if (internalTransferForm?.sourceLoc === null) {
      setValidationAlert({ ...validationAlert, sLocation: "fill this field" });
    } else if (internalTransferForm?.destiLoc === null) {
      setValidationAlert({ ...validationAlert, dLocation: "fill this field" });
    } else if (hasNullProduct) {
      setSnackBarStates({
        success: false,
        error: true,
        alert: true,
        message: "Please select a product for all rows before saving.",
      });
    } else if (hasEmptyTransferQty) {
      setSnackBarStates({
        success: false,
        error: true,
        alert: true,
        message: "Transfer quantity is a required field.",
      });
    } else {
      let draftFn = () => {
        setInternalTransferForm({
          ...internalTransferForm,
          isEdit: true,
          status: "Drafted",
        });
      };
      addInternalTransferAPICall(
        formData,
        draftFn,
        setSnackBarStates,
        snackBarStates,
        setEditDataId,
        setLoadingForm
      );
    }
  };
  // console.log(singleView?.productInfo, "...singleView?.productInfo");
  // useEffect(() => {
  //   if (singleView !== undefined) {
  //     setEditDataId(singleView?._id);
  //     setInternalTransferForm({
  //       ...internalTransferForm,
  //       branch: filterObjFromList(
  //         "_id",
  //         allowedBranchList,
  //         "branchId",
  //         singleView
  //       ),
  //       date: singleView?.date,
  //       reference: singleView?.reference,
  //       contactPerson: singleView?.contactPerson,
  //       editBtn: true,
  //       isEdit: true,
  //       status: singleView?.status,
  //     });
  //     // const updatedTableData = singleView?.productInfo?.map((r) => {
  //     //   if (r?.line_type === "header") {
  //     //     return {
  //     //       type: r?.line_type,
  //     //       HeaderValue: r?.label,
  //     //     };
  //     //   } else {
  //     //     const pro = allNewProductList.find((p) => p._id === r._id) || null;
  //     //     return {
  //     //       type: r?.line_type,
  //     //       product: pro,
  //     //       uom: r?.product?.uomName || r?.uomName,
  //     //       onHandQty: r?.qty,
  //     //       transferQty: r?.transferQty,
  //     //       prodId: r?._id,
  //     //     };
  //     //   }
  //     // });

  //     // setTableData(updatedTableData);
  //     // setTableData(
  //     //   singleView?.productInfo?.map((r, i) => {
  //     //     if (r?.line_type === "header") {
  //     //       return {
  //     //         type: r?.line_type,
  //     //         HeaderValue: r?.label,
  //     //       };
  //     //     } else {

  //     //       // const productObj = {
  //     //       //   _id: r?._id,
  //     //       //   productName: r?.product,
  //     //       //   mainCatgeoryId: r?.mainCatgeoryId,
  //     //       //   uomName: r?.uomName,
  //     //       //   stock: r?.qty,
  //     //       //   poscat: r?.posCat,
  //     //       //   cost: r?.unitPrice,
  //     //       // };
  //     //       // return {
  //     //       //   type: r?.line_type,
  //     //       //   product: productObj,
  //     //       //   uom: r?.uomName,
  //     //       //   onHandQty: r?.qty,
  //     //       //   transferQty: r?.transferQty,
  //     //       //   prodId: r?._id,
  //     //       // };
  //     //       return {
  //     //         type: r?.line_type,
  //     //         // product: filterObjFromList(
  //     //         //   "productName",
  //     //         //   allNewProductList,
  //     //         //   "product",
  //     //         //   r
  //     //         // ),
  //     //         product: allNewProductList.find((p) => p._id === r._id) || null,
  //     //         // product: allNewProductList.find((p) => p.productName === r.product) || null,
  //     //         des: r?.description,
  //     //         uom: r.product.uomName,
  //     //         onHandQty: r?.qty,
  //     //         transferQty: r?.transferQty,
  //     //         prodId: r?._id,
  //     //       };
  //     //     }
  //     //   })
  //     // );
  //     if (singleView?.branchId !== internalTransferForm.branch?._id) {
  //       wareHouseListAPICall({
  //         branchId: singleView?.branchId,
  //       });
  //     }
  //   } else {
  //     setEditClicked(false);
  //   }
  // }, [
  //   singleView,
  //   allowedBranchList,
  //   allNewProductList,
  //   internalTransferForm.branch,
  // ]);

    useEffect(() => {
      if (singleView !== undefined) {
        setEditDataId(singleView?._id);
        setInternalTransferForm({
          ...internalTransferForm,
          branch: filterObjFromList(
            "_id",
            allowedBranchList,
            "branchId",
            singleView
          ),date: singleView?.date,
          reference: singleView?.reference,
          contactPerson: singleView?.contactPerson,
          editBtn: true,
          isEdit: true,
          status: singleView?.status,
        });
        
        setTableData(
          singleView?.productInfo?.map((r, i) => {
            if (r?.line_type === "header") {
              return {
                type: r?.line_type,
                HeaderValue: r?.label,
              };
            } else {
              const matchedProduct = allNewProductList.find(
                (product) => product.productName === r.product
              );
              return {
                type: r?.line_type,
                // product: filterObjFromList(
                //   "productName",           // Match with productName in allNewProductList
                //   allNewProductList,
                //   "product",               // This is the name in productInfo
                //   r
                // ),
                product: matchedProduct || { productName: r?.product }, 
                // product: r?.product,
                des: r?.description,
                uom: r?.uomName,
                onHandQty: r?.onHandQty,
                transferQty: r?.transferQty,
                prodId: r?._id,
              };
            }

            
          }
        )
        );
        wareHouseListAPICall({
          branchId: singleView?.branchId
        });
      } else {
        setEditClicked(false);
      }
    }, [singleView, allowedBranchList]);
   
    
  useEffect(() => {
    setInternalTransferForm({
      ...internalTransferForm,
      wareHouse: filterObjFromList(
        "_id",
        wareHouseArray,
        "warehouseId",
        singleView
      ),
    });
  }, [wareHouseArray]);
  useEffect(() => {
    setInternalTransferForm({
      ...internalTransferForm,
      sourceLoc: filterObjFromList("_id", sourceLoc, "source", singleView) || null,
    });
  }, [sourceLoc]);

  useEffect(() => {
    setInternalTransferForm({
      ...internalTransferForm,
      destiLoc: filterObjFromList(
        "_id",
        destinationLocArray,
        "destination",
        singleView
      ) || null,
    });
  }, [destinationLocArray]);

  const postInternalTransfer = () => {
    let editFn = () => {
      setInternalTransferForm({
        ...internalTransferForm,
        status: "Completed",
        isEdit: true,
      });
    };
    if (singleView !== undefined) {
      postInternalTransferAPICall(
        { _id: singleView?._id },
        editFn,
        setSnackBarStates,
        snackBarStates,
        setLoadingForm
      );
    }
  };

  const editSaveFn = () => {
    formData.append("_id", singleView?._id);
    let editFn = () => {
      setEditClicked(false);
      setInternalTransferForm({
        ...internalTransferForm,
        isEdit: true,
        status: "Drafted",
      });
    };
    EditInternalTransferAPICall(
      formData,
      editFn,
      setSnackBarStates,
      snackBarStates,
      setLoadingForm
    );
  };

  const editBtnEnableFn = () => {
    setInternalTransferForm({ ...internalTransferForm, isEdit: false });
    setEditClicked(true);
  };
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const shouldShowAlert = !internalTransferForm?.isEdit;
      if (shouldShowAlert) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [internalTransferForm?.isEdit]);

  useEffect(() => {
    // apiCall for get product
    if (internalTransferForm.branch && internalTransferForm.sourceLoc) {
      getLocationProductListApi({
        branchId: internalTransferForm.branch._id,
        locationId: internalTransferForm.sourceLoc._id,
      });
    }
  }, [internalTransferForm.branch, internalTransferForm.sourceLoc]);

  useEffect(() => {
    if (allNewProductListRedux) {
      // Use filter to keep only elements where res.stock > 0
      const sortedArray = allNewProductListRedux?.filter(
        (res) => res.stock > 0
      );
      setAllNewProductList(sortedArray);
    }
  }, [allNewProductListRedux]);

  return (
    <div className="global-page-parent-container">
      <div
        className="new-global-white-bg-container"
        style={{ padding: "0 2%" }}
      >
        <div className="new-global-white-bg-icon-container">
          <div className="new-global-white-bg-icon-container-left">
            <Tooltip title="Back" onClick={backButtonFn}>
              <IconButton>
                <i class="bi bi-chevron-left"></i>
              </IconButton>
            </Tooltip>
            {internalTransferForm?.status === "Drafted" && (
              <Tooltip title="Edit">
                <IconButton
                  onClick={editClicked ? editSaveFn : editBtnEnableFn}
                >
                  {editClicked ? (
                    <i
                      class="bi bi-check2-square edit-icon"
                      style={{ fontSize: "1.5rem" }}
                    ></i>
                  ) : (
                    <i class="bi bi-pencil-square edit-icon1"></i>
                  )}
                </IconButton>
              </Tooltip>
            )}

            {internalTransferForm?.status === "" && (
              <Tooltip title="Save" onClick={saveInternalTransFun}>
                <IconButton>
                  <i class="bi bi-floppy save-icon"></i>
                </IconButton>
              </Tooltip>
            )}

            {internalTransferForm?.status === "Drafted" && !editClicked && (
              <button onClick={postInternalTransfer} className="btn btn-post">
                Post
              </button>
            )}
          </div>
          {
            <div className="status-container">
              {internalTransferForm?.status === "Drafted" && (
                <img src={draftRibbon} alt="" />
              )}
              {internalTransferForm?.status === "Completed" && (
                <img src={postRibbon} alt="" />
              )}
            </div>
          }
        </div>
        <hr className="global-hr" />
        <h4 className="global-name">
          {singleView?.transNo || generatedName?.codeprefix || "###"}
        </h4>
        <div className="new-global-input-container">
          <div className="new-global-single-input auto-complete-new">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={allowedBranchList || []}
              sx={{ width: 300 }}
              getOptionLabel={(option) =>
                `${option?.storeCode}-${option?.branchName}`
              }
              renderInput={(params) => (
                <TextField {...params} label="Branch*" focused />
              )}
              value={internalTransferForm?.branch}
              onChange={getInternalTransForm("branch")}
              disabled={internalTransferForm?.isEdit}
            />
            <p className="doc-validation-alert">{validationAlert?.branch}</p>
          </div>
          <div className="new-global-single-input">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={wareHouseArray || []}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="WareHouse*" focused />
              )}
              value={internalTransferForm?.wareHouse}
              onChange={getInternalTransForm("wareHouse")}
              disabled={internalTransferForm?.isEdit}
            />
            <p className="doc-validation-alert">{validationAlert?.wareHouse}</p>
          </div>
          <div className="new-global-single-input">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={sourceLoc || []}
              sx={{ width: 300 }}
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <TextField {...params} label="Source Location*" focused />
              )}
              value={internalTransferForm?.sourceLoc}
              onChange={getInternalTransForm("sourceLoc")}
              disabled={internalTransferForm?.isEdit}
            />
            <p className="doc-validation-alert">{validationAlert?.sLocation}</p>
          </div>
          <div className="new-global-single-input">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={destinationLocArray || []}
              getOptionLabel={(option) => option?.name}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Destination Location*" focused />
              )}
              value={internalTransferForm?.destiLoc}
              onChange={getInternalTransForm("destiLoc")}
              disabled={internalTransferForm?.isEdit}
            />
            <p className="doc-validation-alert">{validationAlert?.dLocation}</p>
          </div>
          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Date*"
              variant="outlined"
              type="date"
              focused
              value={internalTransferForm?.date}
              onChange={getInternalTransForm("date")}
              disabled={internalTransferForm?.isEdit}
            />
          </div>
          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Reference*"
              variant="outlined"
              type="text"
              focused
              value={internalTransferForm?.reference}
              onChange={getInternalTransForm("reference")}
              disabled={internalTransferForm?.isEdit}
            />
          </div>
          <div className="new-global-single-input">
            <TextField
              id="outlined-basic"
              label="Contact Person*"
              variant="outlined"
              type="text"
              focused
              value={internalTransferForm?.contactPerson}
              onChange={getInternalTransForm("contactPerson")}
              disabled={internalTransferForm?.isEdit}
            />
          </div>
        </div>
      </div>
      <div className="new-global-white-bg-container">
        <div className="global-product-table">
          <table>
            <thead>
              <tr>
                <th>Product</th>
                <th>UOM</th>
                <th>On Hand Qty</th>
                <th>Transfer Qty</th>
                <th style={{ backgroundColor: "#fff" }}></th>
              </tr>
            </thead>

            <tbody>
              {tableData?.map((item, index) => (
                <tr
                  key={index}
                  ref={index === tableData.length - 1 ? containerRef : null}
                >
                  {item.type === "header" ? (
                    <>
                      <td colSpan="4">
                        <input
                          type="text"
                          value={item.HeaderValue}
                          onChange={handleInputChange(index, "HeaderValue")}
                          disabled={internalTransferForm?.isEdit}
                        />
                      </td>
                      <td style={{ border: "none", width: "4%" }}>
                        <IconButton
                          onClick={() => handleDelete(index)}
                          style={{ height: "30px" }}
                          disabled={internalTransferForm?.isEdit}
                        >
                          <i
                            className="bi bi-trash3"
                            style={{ color: "#db0000", fontSize: "1.3rem" }}
                          ></i>
                        </IconButton>
                      </td>
                    </>
                  ) : (
                    <>
                      <td style={{ minWidth: "200px" }}>
                        <div className="product-table-auto-complete">
                          <Autocomplete
                            options={allNewProductList || []}
                            style={{ maxHeight: "100px" }}
                            getOptionLabel={(option) => option?.productName}
                            renderInput={(params) => (
                              <TextField
                                // inputRef={inputRef}
                                // onChange={(e) =>
                                //   productSearchApi({
                                //     search: e.target.value,
                                //   })
                                // }
                                {...params}
                              />
                            )}
                            // isOptionEqualToValue={(option, value) => option._id === value._id}
                            isOptionEqualToValue={(option, value) => {
                              // Handle both string and object cases
                              if (typeof value === "string") {
                                return option.productName === value;
                              }
                              return option._id === value._id;
                            }}
                            renderOption={(props, option) => (
                              <li {...props} key={option._id}>
                                <span>{option?.productName}</span>
                              </li>
                            )}
                            value={item?.product } 
                            onChange={handleInputChange(index, "product")}
                            disabled={internalTransferForm?.isEdit}
                          />
                        </div>
                      </td>
                      <td>
                        <input type="text" value={item.uom} disabled />
                      </td>

                      <td>
                        <input type="text" value={item.onHandQty} disabled />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={item.transferQty}
                          onChange={handleInputChange(index, "transferQty")}
                          disabled={
                            internalTransferForm?.isEdit || item.onHandQty === 0
                          }
                        />
                      </td>
                      <td style={{ border: "none", width: "4%" }}>
                        <IconButton
                          onClick={() => handleDelete(index)}
                          style={{ height: "30px" }}
                          disabled={internalTransferForm?.isEdit}
                        >
                          <i
                            className="bi bi-trash3"
                            style={{ color: "#db0000", fontSize: "1.3rem" }}
                          ></i>
                        </IconButton>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="add-row-btn-container">
          <div
            className="global-single-input auto-complete add-line-autocomplete"
            style={{ width: "76%" }}
          >
            <Autocomplete
              options={addRowData || []}
              getOptionLabel={(option) => option?.value}
              renderInput={(params) => <TextField {...params} />}
              value={addRowInput}
              onChange={(e, newValue) => setAddRowInput(newValue)}
              renderClear={() => null}
              disabled={internalTransferForm?.isEdit}
            />
          </div>
          <button
            onClick={() => addRow()}
            className="add-row-btn"
            disabled={internalTransferForm?.isEdit}
          >
            +
          </button>
        </div>
      </div>
      {singleView !== undefined && (
        <div
          className="new-global-white-bg-container new-log-container"
          style={{ padding: "4px" }}
        >
          <div
            style={{ padding: "8px", cursor: "pointer" }}
            onClick={clickLogView}
          ></div>
          <div
            className={logView ? "pointer-up" : "pointer"}
            onClick={clickLogView}
            style={{ cursor: "pointer" }}
          ></div>
          {logView && (
            <div className="single-log-container">
              {singleView?.log?.map((r, i) => (
                <div className="single-log-item">
                  <div className="single-log-item-line-image">
                    <hr className="single-log-item-line" />
                    <div className="single-log-item-image">
                      <img src={logoImage} alt="" />
                    </div>
                    <hr className="single-log-item-line" />
                  </div>
                  <p className="heading">{r?.status}</p>
                  <p className="name">{r?.empId}</p>
                  <p className="date-time">{r?.date}</p>
                </div>
              ))}
            </div>
          )}

          {/* <div className='single-log-container-scroll-line'></div> */}
        </div>
      )}

      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <LoadingForm loading={loadingForm} />
    </div>
  );
};
