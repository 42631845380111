import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { convertDateFormat } from '../../../../Js/Date';
import { Skeleton } from '@mui/material';

const CustomerCreditReportprint = (props) => {
    const {SingleListData} =props
  const [isLoading, setIsLoading] = useState(false);

    const companyProfileData = useSelector(
        (state) => state?.companyProfileSlice?.userValue
    );
  const decimalPosition = localStorage.getItem("decimalPosition");

  return (
    <div className="print-main-div" >
        <div className='header-div' style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    <img src={companyProfileData?.companyLogo} alt="" style={{ height: "62px" }} />
                </div>
                <div style={{ textAlignLast: "center" }}>
                    <h1 style={{ fontSize: "medium", margin: "0" }}>Customer Credit Report</h1>

                    {/* <p style={{margin:"0" ,fontSize: "small"}}>--</p> */}


                </div>
                <div>
                    {/* <h1 style={{ fontSize: "small", margin: "0" }}>companyName</h1> */}
                    <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyName}</p>
                    <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyAddress}</p>
                    <p style={{ margin: "0", fontSize: "smaller" }}>{companyProfileData?.companyContact}</p>
                </div>
            </div>
            <div className="customer-receipt-table-container" style={{overflow:"visible"}}>
          <table>
            <thead>
              <tr>

                <th>CUSTOMERS</th>
                <th>MOBILE</th>
                <th>LAST PAID DATE</th>
                <th>WORK ORDER</th>
                <th>ALTERATION</th>
                <th>BILLING</th>
                <th>TOTAL</th>
                <th>DISCOUNT</th>
                <th>PAID</th>
                <th>BALANCE</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : SingleListData?.report !== undefined && SingleListData?.report?.length !== 0 ? (
                SingleListData?.report
                  ?.slice(0)
                  ?.map((r, i) => (
                    <tr key={i} >
                      <td style={{ width: "25px" }}>{r?.customerName}</td>
                      <td>{r?.customerMobile}</td>
                      <td>{convertDateFormat(r?.date)}</td>
                      <td style={{ textAlign: 'end', paddingRight: '3%' }}>{r?.workOrder?.toFixed(decimalPosition)}</td>
                      <td>{r?.alteration}</td>
                      <td>{r?.billing}</td>
                      <td>{r?.total}</td>
                      <td>{r?.discount}</td>
                      <td>{r?.paidAmount}</td>
                      <td>{r?.balance}</td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td style={{ textAlign: 'center' }} colSpan={10}>No Data</td>
                </tr>
              )}

            </tbody>
            <tfoot >

              <tr>
                <th style={{ textAlign: 'start', fontSize: '1rem', paddingLeft: '5%' }} colSpan={3}>TOTAL</th>
                <th >{SingleListData?.total !== undefined && SingleListData?.total?.workOrder?.toFixed(decimalPosition)}</th>
                <th >{SingleListData?.total !== undefined && SingleListData?.total?.alteration?.toFixed(decimalPosition)}</th>
                <th >{SingleListData?.total !== undefined && SingleListData?.total?.total?.toFixed(decimalPosition)}</th>
                <th >{SingleListData?.total !== undefined && SingleListData?.total?.discount?.toFixed(decimalPosition)}</th>
                <th >{SingleListData?.total !== undefined && SingleListData?.total?.discount?.toFixed(decimalPosition)}</th>
                <th >{SingleListData?.total !== undefined && SingleListData?.total?.discount?.toFixed(decimalPosition)}</th>
                <th >{SingleListData?.total !== undefined && SingleListData?.total?.billing?.toFixed(decimalPosition)}</th>
              </tr>
            </tfoot>
          </table>
        </div>
    </div>
  )
}

export default CustomerCreditReportprint