import React, { useEffect, useState } from "react";
import { IconButton, Skeleton, Tooltip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { filterObjFromList } from "../../../../Js/generalFunctions";
import CategorySearchandFilter from "../../../Single Components/CategorySearchandFilter";
import { DailySalesReportAPIcall } from "./SalesReportApi";
import axios from "axios";
import { companyProfileInfoForUser } from "../../../../API/Settings/Company Settings/companySettingsAPI";
import { viewAllCurrencyUpdated } from "../../../../API/Settings/Currency/CurrencyAPI";
import CustomPagination from "../../../Single Components/CustomPagination";
import { convertDateFormat, previousDate, today } from "../../../../Js/Date";
import { setFilterActive } from "../../../Single Components/listSlice/filterCategorySlice";
import store from "../../../../Redux/store";
import ReactToPrint from "react-to-print";
import ExcelJS from "exceljs";

function DailySalesReport() {
  let navigate = useNavigate();
  let location = useLocation();
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );
  const filteredData = useSelector(
    (state) => state.salesQuatationSlice.quotationFilter
  );
  const companyProfileData = useSelector(
    (state) => state?.companyProfileSlice?.userValue
  );

  const SingleListData = useSelector(
    (state) => state?.salesReport?.dailySalesReport
  );
  const totalPaidAmtKeys =
    SingleListData !== undefined
      ? Object.keys(SingleListData?.total?.totalPaidAmount)
      : [];

  const [FormDataInfo, setFormDataInfo] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [filteredDatas, setFilteredData] = useState({});
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch);
  };
  const [symbol, setSymbol] = useState(null);
  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };

  const totalPages = 10;
  const decimalPosition = localStorage.getItem("decimalPosition");
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.toDate && filters.fromDate) {
      parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
    }
    if (filters?.branchList) {
      parts.push(
        `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
      );
    }
    if (filters?.location) {
      parts.push(`location: ${filters?.location?.name}`);
    }
    if (filters?.toLocation) {
      parts.push(`To Location: ${filters?.toLocation?.name}`);
    }
    if (filters?.status) {
      parts.push(`status: ${filters?.status}`);
    }
    if (filters?.createdBy) {
      parts.push(`createdBy: ${filters?.createdBy?.staff_name}`);
    }
    return parts; // Return the array directly
  };
  const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Daily Sales Report");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Daily Sales Report"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells("A1:F1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredDatas) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:F${index + 2}`);
        row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow([
      "DATE",
      "INV COUNT",
      "INV AMOUNT",
      "RETURN AMOUNT",
      "TOTAL PAID",
      "CREDIT",
    ]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };

    headerRow.eachCell((cell, colNumber) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "DATE", key: "date", width: 20 },
      { header: "INV COUNT", key: "invoiceCount", width: 20 },
      { header: "INV AMOUNT", key: "invAmount", width: 25 },
      { header: "RETURN AMOUNT", key: "returnAmount", width: 25 },
      { header: "TOTAL PAID", key: "total", width: 25 },
      { header: "Daily Sales Report", key: "amountDue", width: 15 },
    ];

    filteredData?.list?.forEach((item, index) => {
      const row = sheet.addRow({
        date: convertDateFormat(item?._id),
        invoiceCount: item?.invoiceCount,
        invAmount: item.invAmount,
        returnAmount: item?.returnAmount,
        total: item?.total,
        amountDue: item?.amountDue,
      });

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          //   top: { style: "thin", color: { argb: "FF000000" } },
          //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FFedf2f9" } },
          //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >= 1) {
          // Right align all columns except the first
          cell.alignment = { horizontal: "center" };
        }
      });
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Daily Sales Report.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  const handleClick = (row) => {
    store.dispatch(setFilterActive(window.location.pathname));
    localStorage.setItem("SingleViewId", row?._id);
    navigate("/userdashboard/sales/Report/dailySalesReport/SingleView", {
      state: { date: row?._id },
    });
  };

  useEffect(() => {
    if (FormDataInfo?.length !== 0) {
      let body = {
        fromDate:
          FormDataInfo.fromDate === ""
            ? `${previousDate} 00:00:00`
            : `${FormDataInfo.fromDate} 00:00:00`,
        toDate:
          FormDataInfo?.toDate === ""
            ? `${today} 23:59:59`
            : `${FormDataInfo?.toDate} 23:59:59`,
        branchId: FormDataInfo?.branchList,
      };
      let loadData =
        window.location.pathname === filtereDataOptions?.filterActive;
      !loadData && DailySalesReportAPIcall(body, setIsLoading);
    }
  }, [FormDataInfo]);

  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);

  return (
    <div className="global-page-parent-container">
      {/* table */}
      <div className="global-white-bg-container">
        <div
          className="create-button-blue-container"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          {FormDataInfo?.branchList?.length > 0 && (
            <div>
              <h3>
                Branch name:
                {selectedBranch?.storeCode ||
                  filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                -
                {selectedBranch?.branchName ||
                  filtereDataOptions?.filterCategorylist?.branchList
                    ?.branchName}
              </h3>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
            }}
          >
            <CategorySearchandFilter
              onData={handleDataFromChild}
              style={{ width: "100%" }}
              onBranchSelect={handleBranchSelect}
              isSearch={false}
              onFilterChange={handleFilterChange} 
            />
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tooltip title="Share">
                  <IconButton>
                    <i
                      class="bi bi-share"
                      style={{
                        backgroundColor: "#9797e9",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>

                <ReactToPrint
                  trigger={() => (
                    <IconButton
                      className="printer-div"
                      style={{ padding: "1px" }}
                    >
                      <i
                        style={{
                          color: "white",
                          backgroundColor: " #d787e7",
                          fontSize: "medium",
                          padding: "8px",
                          borderRadius: "2px",
                        }}
                        class="bi bi-printer"
                      ></i>
                    </IconButton>
                  )}
                  // content={() => paymentsPrintNew}
                />
                <Tooltip title="Download">
                  <IconButton onClick={downloadList}>
                    <i
                      class="bi bi-arrow-down-circle"
                      style={{
                        backgroundColor: "rgb(255 118 133 / 69%)",
                        color: "white",
                        fontSize: "medium",
                        padding: "8px",
                        borderRadius: "2px",
                      }}
                    ></i>
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>

        <div className="create-button-blue-container">
          <h3>Daily Sales Report</h3>
          {/* <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        /> */}
        </div>
        <div className="customer-receipt-table-container">
          <table>
            <thead>
              <tr>
                <th>DATE</th>
                <th>INV COUNT</th>
                <th>INV AMOUNT</th>
                <th>RETURN AMOUNT</th>
                <th>TOTAL PAID</th>
                {totalPaidAmtKeys?.map((r) => (
                  <th style={{ textAlignLast: "center" }}>{r}</th>
                ))}
                {/* <th>CASH</th>
              <th>BANK</th> */}
                <th>CREDIT</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : SingleListData?.report !== undefined &&
                SingleListData?.report?.length !== 0 ? (
                SingleListData?.report?.slice(0)?.map((r, i) => (
                  <tr key={i} onClick={() => handleClick(r)}>
                    <td>{convertDateFormat(r?._id)}</td>
                    <td>{r?.invoiceCount}</td>
                    <td>{r?.invAmount?.toFixed(decimalPosition) || "0.00"}</td>
                    <td>
                      {r?.returnAmount?.toFixed(decimalPosition) || "0.00"}
                    </td>
                    <td>{r?.total?.toFixed(decimalPosition) || "0.00"}</td>
                    {totalPaidAmtKeys?.map((key) => (
                      <td key={key}>
                        {symbol}&nbsp;
                        {r?.totalPaidAmount[key]?.toFixed(decimalPosition) ||
                          "0.00"}
                      </td>
                    ))}
                    {/* <td>{r?.totalPaidAmount?.Cash?.toFixed(decimalPosition)}</td>
                    <td>{r?.totalPaidAmount?.Bank?.toFixed(decimalPosition)}</td> */}
                    <td>{r?.amountDue?.toFixed(decimalPosition) || "0.00"}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td style={{ textAlign: "center" }} colSpan={10}>
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <th
                  style={{
                    textAlign: "start",
                    fontSize: "1rem",
                    paddingLeft: "5%",
                  }}
                  colSpan={2}
                >
                  TOTAL(AED)
                </th>

                <th>
                  {(SingleListData?.total !== undefined &&
                    SingleListData?.total?.invAmount?.toFixed(
                      decimalPosition
                    )) ||
                    "0.00"}
                </th>
                <th>
                  {(SingleListData?.total !== undefined &&
                    SingleListData?.total?.returnAmountSum?.toFixed(
                      decimalPosition
                    )) ||
                    "0.00"}
                </th>
                <th>
                  {(SingleListData?.total !== undefined &&
                    SingleListData?.total?.total?.toFixed(decimalPosition)) ||
                    "0.00"}
                </th>
                {totalPaidAmtKeys.map((key) => (
                  <th key={key}>
                    {SingleListData?.total?.totalPaidAmount[key]?.toFixed(
                      decimalPosition
                    ) || "0.00"}
                  </th>
                ))}
                {/* <th >{SingleListData?.total !== undefined && SingleListData?.total?.totalPaidAmount?.Cash?.toFixed(decimalPosition)}</th>
              <th >{SingleListData?.total !== undefined && SingleListData?.total?.totalPaidAmount?.Bank?.toFixed(decimalPosition)}</th> */}
                <th>
                  {(SingleListData?.total !== undefined &&
                    SingleListData?.total?.amountDue?.toFixed(
                      decimalPosition
                    )) ||
                    "0.00"}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}

export default DailySalesReport;
