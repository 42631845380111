import { utils, write } from 'xlsx-js-style';

const exportToExcel = ({
  data = [],  
  headers = [],
  title = 'Report',
  filename = 'export.xlsx',
  styleConfig = {
    headerStyle: {
      fill: { fgColor: { rgb: 'EDF2F9' } },
      font: { bold: true, sz: 12 },
      alignment: { horizontal: 'center', vertical: 'center' }
    },
    cellStyle: {
      alignment: { horizontal: 'center' },
      border: {
        bottom: { style: 'thin', color: { rgb: 'EDF2F9' } }
      }
    }
  }
}) => {
  // Validate inputs
  if (!Array.isArray(data)) {
    console.error('Data must be an array');
    return;
  }

  if (!Array.isArray(headers) || headers.length === 0) {
    console.error('Headers must be a non-empty array');
    return;
  }

  try {
    // Create workbook and worksheet
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);

    // Add title
    utils.sheet_add_aoa(ws, [[title]], { origin: 'A1' });
    ws['A1'].s = {
      font: { bold: true, sz: 15 },
      alignment: { horizontal: 'center', vertical: 'center' }
    };
    ws['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: headers.length - 1 } }];

    // Add headers
    const headerRow = headers.map(h => h.label);
    utils.sheet_add_aoa(ws, [headerRow], { origin: 'A3' });

    // Style headers
    const headerRange = utils.decode_range('A3:' + utils.encode_col(headers.length - 1) + '3');
    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
      const addr = utils.encode_cell({ r: 2, c: C });
      if (ws[addr]) {
        ws[addr].s = styleConfig.headerStyle;
      }
    }

    // Format and add data
    const formattedData = data.map(row => 
      headers.map(header => {
        const value = row[header.key];
        return value !== undefined ? value : ''; // Handle undefined values
      })
    );

    if (formattedData.length > 0) {
      utils.sheet_add_aoa(ws, formattedData, { origin: 'A4' });

      // Style data cells
      const dataRange = utils.decode_range(ws['!ref']);
      for (let R = 3; R <= dataRange.e.r; ++R) {
        for (let C = dataRange.s.c; C <= dataRange.e.c; ++C) {
          const addr = utils.encode_cell({ r: R, c: C });
          if (ws[addr]) {
            ws[addr].s = styleConfig.cellStyle;
          }
        }
      }
    }

    // Set column widths
    ws['!cols'] = headers.map(h => ({ wch: h.width || 15 }));

    // Add worksheet to workbook and download
    utils.book_append_sheet(wb, ws, 'Sheet1');
    const wbout = write(wb, { bookType: 'xlsx', type: 'binary' });
    
    // Create blob and trigger download
    const buf = new ArrayBuffer(wbout.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xFF;
    
    const blob = new Blob([buf], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error exporting to Excel:', error);
  }
};

export default exportToExcel;