import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import store from "../../../../../Redux/store";
import { convertDateFormat, getCurrentTime } from "../../../../../Js/Date";
import { get_stock_move_row_data_list } from "./stockMovesSlice";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  sortViewStockMovesListAPICall,
  viewStockMovesListAPICall,
} from "./stockMovesAPI";
import { Skeleton } from "@mui/material";
import CategorySearchandFilter from "../../../../Single Components/CategorySearchandFilter";
import CustomPagination from "../../../../Single Components/CustomPagination";
import axios from "axios";
import { setFilterActive } from "../../../../Single Components/listSlice/filterCategorySlice";
export const StockMoves = () => {
  let navigate = useNavigate();
  let navigateTo = useNavigate();
  const tableListData = useSelector((state) => state.stockMoveSlice.apiResList);
  const filtereDataOptions = useSelector((state) => state.listCategory);

  const [isLoading, setIsLoading] = useState(false);
  const [FormDataInfo, setFormDataInfo] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const handleClickRow = (row) => {
    store.dispatch( setFilterActive(window.location.pathname))
    store.dispatch(get_stock_move_row_data_list({ stockMoveSingleData: row }));
    // navigateTo("/userdashboard/accounts/vendor/createvendorBills");
  };

  useEffect(() => {
    store.dispatch(
      get_stock_move_row_data_list({ stockMoveSingleData: undefined })
    );
    // sortViewStockMovesListAPICall(
    //   {
    //     fromDate: `${FormDataInfo.fromDate} ${getCurrentTime()}`,
    //     toDate: `${FormDataInfo.toDate} ${getCurrentTime()}`,
    //   },
    //   setIsLoading
    // );
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch); 
  
  };

  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };
  useEffect(() => {
   
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData &&   sortViewStockMovesListAPICall(
        {
          fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
          toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
          search: FormDataInfo?.search,
          index: currentPage - 1,
          type: FormDataInfo?.type,
          fromLocation: FormDataInfo?.fromLocation,
          toLocation: FormDataInfo?.toLocation,
          product: FormDataInfo?.product?._id,
          branchList: FormDataInfo.branchList,
        },
        setIsLoading
      );
    }
  
  }, [FormDataInfo, currentPage]);
  const typeList = [
    { label: "BRANCH TRANSFER", type: "subheading" },
    { label: "DELIVERY NOTE", type: "subheading" },
  ];
  return (
    <div className="global-page-parent-container">
      {/* <p className="breadcrumb">Inventory &gt; Operation &gt; Stock Moves</p> */}

      <div className="global-white-bg-container">
        <div className="justify-end">&nbsp;</div>
        <div
          className="justify-space-between"
          style={{ gap: "0 12px", display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}
        >
           {FormDataInfo?.branchList?.length > 0 && (
              <div style={{ marginLeft: '1%' }}>
                <h3>Branch name: 
                  {selectedBranch?.storeCode||filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                  -{selectedBranch?.branchName||filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
              </div>
            )}
             <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: FormDataInfo?.branchList?.length ? '72%' : '100%'
            }}>
          <CategorySearchandFilter
            onData={handleDataFromChild}
            isType={true}
            isProduct={true}
            isFromLocation={true}
            isToLocation={true}
            typeList={typeList}
            onBranchSelect={handleBranchSelect}
          />
          </div>
        </div>
        <div className="create-button-blue-container">
          <h3>Stock Moves</h3>
          
            <CustomPagination
              currentPage={currentPage}
              totalPages={tableListData?.pages}
              onPageChange={handlePageChange}
              setFormDataInfo={setFormDataInfo}
            />
        
        </div>
        <div className="global-table-container">
          <table className="global-table">
            <thead>
              <tr>
                <th style={{ width: "80px" }}>Date</th>
                <th>Reference</th>
                <th>Product</th>
                <th>From</th>
                <th>To</th>
                <th>Quantity</th>
                <th>UOM</th>
                <th>Branch</th>
                <th>Type</th>
                {/* <th>
              <ArrowUpwardIcon sx={{color:"red"}}/>
              <ArrowDownwardIcon sx={{color:"#00ef00"}}/>
              </th> */}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : tableListData !== undefined &&
                tableListData?.list &&
                tableListData.list.length !== 0 ? (
                tableListData?.list
                  ?.map((r, i) => (
                    <tr
                      key={i}
                      onClick={() => handleClickRow(r)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{r?.date}</td>
                      <td>{r?.reference}</td>
                      <td>{r?.product}</td>
                      <td>{r?.from1}</td>
                      <td>{r?.to1}</td>
                      <td>{r?.Quantity}</td>
                      <td>{r?.uom}</td>
                      <td>{r?.branchId}</td>
                      <td>{r?.type}</td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={11}>No data</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
