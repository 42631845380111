import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, IconButton, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { getAllDesignationAPICall } from '../../../../API/Staff/HRM/departmentAndDesignationAPI';
import { useSelector } from 'react-redux';
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ErrorSnackbar from '../../../Single Components/SnackBars/ErrorSnackbar';
import { addEmployeeRolesAndPermissionAPICall, getRolesAndPermissionAPICall } from '../../../../API/Settings/Roles and permission/rolesAndPermissionAPI';
import SuccessSnackbar from '../../../Single Components/SnackBars/SuccessSnackbar';
export const RolesAndPermissionNew = () => {

   
   const [purchaseSection,setPurchaseSection]=useState([
    {label:"All",isChecked:false,key:"all"},
    {label:"Request For Quotation",isChecked:false,key:"rfq"},
    {label:"Purchase Order",isChecked:false,key:"purchaseOrder"},
    {label:"Goods Received Note",isChecked:false,key:"grn"},
    {label:"Unit Of Measurment",isChecked:false,key:"uom"},
    {label:"Vendors",isChecked:false,key:"vendors"},
    {label:"Configuration",isChecked:false,key:"configuration"},
    {label:"Purchase Report",isChecked:false,key:"purchaseReport"},

   ])
   const [inventorySection,setInventortSection]=useState([
    {label:"All",isChecked:false,key:"all"},
    {label:"Product Master",isChecked:false,key:"productMaster"},
    {label:"Product",isChecked:false,key:"product"},
    {label:"Internal Transfer",isChecked:false,key:"internalTransfer"},
    {label:"Branch Transfer",isChecked:false,key:"branchTransfer"},
    {label:"Branch Receipts",isChecked:false,key:"branchReceipts"},
    {label:"Stock Moves",isChecked:false,key:"stockMoves"},
    {label:"Inventory Adjustment",isChecked:false,key:"inventoryAdjustment"},
    {label:"Landed Cost",isChecked:false,key:"landedCost"},
    {label:"Warehouse",isChecked:false,key:"warehouse"},
    {label:"Location",isChecked:false,key:"location"},
    {label:"Settings",isChecked:false,key:"settings"},
    {label:"Attribute",isChecked:false,key:"attribute"},
    {label:"POS Category",isChecked:false,key:"posCategory"},
    {label:"Category",isChecked:false,key:"category"},
    {label:"Stock Moves Report",isChecked:false,key:"stockMovesReport"},
   ])
   const [salesSection,setSalesSection]=useState([
    {label:"All",isChecked:false,key:"all"},
    {label:"Quotation",isChecked:false,key:"quotation"},
    {label:"Sales Order",isChecked:false,key:"salesOrder"},
    {label:"Delivery Note",isChecked:false,key:"deliveryNote"},
    {label:"Customers",isChecked:false,key:"customer"},
    {label:"Price List",isChecked:false,key:"priceList"},
    {label:"Sales Report",isChecked:false,key:"salesReport"},
   ])
   const [posSection,setPosSection]=useState([
    {
        name:"Orders",
        nameKey:"orders",
        values:[
            {label:"All",isChecked:false,key:'all'},
            {label:"ViewOrders",isChecked:false,key:'viewOrders'},
            {label:"WorkOrder",isChecked:false,key:'workOrder'},
            {label:"WorkOrder Edit After Shift End",isChecked:false,key:'woEdit'},
            {label:"PrintCuttingSlip",isChecked:false,key:'printCuttingSlip'},
            {label:"Alteration",isChecked:false,key:'alteration'},
            {label:"Alteration Edit",isChecked:false,key:'altEdit'},
            {label:"Job Completion",isChecked:false,key:'jobCompletion'},
            {label:"Delivery",isChecked:false,key:'delivery'},
        ]
    },
    {
        name:"Billing",
        nameKey:"billing",
        values:[
            {label:"All",isChecked:false,key:"all"},
            {label:"Billing",isChecked:false,key:"billing"},
            {label:"Order List",isChecked:false,key:"orderList"},
            {label:"Receipts",isChecked:false,key:"receipts"},
            {label:"Return",isChecked:false,key:"return"},
            {label:"Credit",isChecked:false,key:"credit"},
            {label:"Wallet",isChecked:false,key:"wallet"},
            {label:"Old Stock",isChecked:false,key:"oldStock"},
            {label:"Damaged Goods",isChecked:false,key:"damagedGoods"},
        ]
    },
    {
        name:"Expense",
        nameKey:"expense",
        values:[
            {label:"All",isChecked:false,key:"all"},
            {label:"Add Expense Type",isChecked:false,key:"addExpenseType"},
            {label:"Staff Expense",isChecked:false,key:"staffExpense"},
            {label:"Outlet Expense",isChecked:false,key:"outletExpense"},
            {label:"Petty Cash Receipts",isChecked:false,key:"pettyCashReciept"},
           
        ]
    },
    {
        name:"Rewards",
        nameKey:"rewards",
        values:[
            {label:"All",isChecked:false,key:'all'},
            {label:"Reward View",isChecked:false,key:'rewardsView'},
            {label:"Add Rewards",isChecked:false,key:'addRewards'},
           
        ]
    },
    {
        name:"Offer",
        nameKey:"offers",
        values:[
            {label:"All",isChecked:false,key:'all'},
            {label:"Offer List",isChecked:false,key:'offerListed'},
            {label:"Add Offer",isChecked:false,key:'addOffer'},
           
        ]
    },
    {
        name:"Configuration",
        nameKey:"configuration",
        values:[
            {label:"All",isChecked:false,key:'all'},
            {label:"Settings",isChecked:false,key:'settings'},
            {label:"Branch Settings",isChecked:false,key:'branchSettings'},
           
        ]
    }
   ])
   const [customerSection,setCustomerSection]=useState([
    {label:"All",isChecked:false,key:'all'},
    {label:"Cutomer List",isChecked:false,key:'customerList'},
    // {label:"Add Customer",isChecked:false,key:'addCustomer'},
    ])
   const [reportSection,setReportSection]=useState([
    {label:"All",isChecked:false,key:'all'},
    {label:"POS Sales Report",isChecked:false,key:'posSalesReport'},
    {label:"Stock Report",isChecked:false,key:'stockReport'},
    {label:"Daily Report",isChecked:false,key:'dailyReport'},
    {label:"Daily Cash/Card Summary",isChecked:false,key:'dailyCashAndCard'},
    {label:"Expense Report",isChecked:false,key:'expenseReport'},
    {label:"Payment Report",isChecked:false,key:'paymentReport'},
    {label:"Commission Report",isChecked:false,key:'commissionReport'},
    {label:"Job Completion Report",isChecked:false,key:'jobCompletion'},
   ])
   const [accountSection,setAccountSection]=useState([
    {
      name:"Customers",
      nameKey:"customer",
      values:[
          {label:"All",isChecked:false,key:"all"},
          {label:"Customer Invoice",isChecked:false,key:"customerInvoices"},
          {label:"Sales W/SO",isChecked:false,key:"salesWso"},
          {label:"Payments",isChecked:false,key:"payments"},
          {label:"Credit Notes",isChecked:false,key:"creditNotes"},
          {label:"Customers",isChecked:false,key:"customers"},
      ]
  },
  {
      name:"Vendor",
      nameKey:"vendor",
      values:[
          {label:"All",isChecked:false,key:"all"},
          {label:"Vendor Bills",isChecked:false,key:"vendorBills"},
          {label:"Purchase W/PO",isChecked:false,key:"purchaseWpo"},
          {label:"Payments",isChecked:false,key:"payments"},
          {label:"Debit Notes",isChecked:false,key:"debitNotes"},
          {label:"Vendors",isChecked:false,key:"vendor"},
      ]
  },
  {
      name:"Accounting",
      nameKey:"accounting",
      values:[
          {label:"All",isChecked:false,key:"all"},
          {label:"Journal",isChecked:false,key:"journal"},
          {label:"Chart Of Accounts",isChecked:false,key:"chartOfAccounts"},
          {label:"Journal Entries",isChecked:false,key:"journalEntries"},
          {label:"Recurring Posting",isChecked:false,key:"recurringPosting"},
         
      ]
  },
  {
      name:"Reports",
      nameKey:"reports",
      values:[
          {label:"All",isChecked:false},
          {label:"Trial Balance",isChecked:false,key:"trialBalance"},
          {label:"Balance Sheet",isChecked:false,key:"balanceSheet"},
          {label:"General Ledger",isChecked:false,key:"generalLedger"},
          {label:"Profit And Loss",isChecked:false,key:"profitAndLoss"},
          {label:"Account Payable",isChecked:false,key:"accountPayable"},
          {label:"Account Receivable",isChecked:false,key:"accountReceivable"},
          {label:"Bank And Cash Report",isChecked:false,key:"bankAndCashReport"},
         
      ]
  },
  {
    name:"Configuration",
    nameKey:"configuration",
    values:[
        {label:"Configuration",isChecked:false,key:"configuration"},
       
       
    ]
},
 
   ])
   const [staffSection,setStaffSection]=useState([
    {
      name:"Dashboard",
      nameKey:"dashboard",
      values:[
          {label:"Dashboard",isChecked:false,key:"dashboard"},
      ]
  },
  {
      name:"HRM",
      nameKey:"HRM",
      values:[
          {label:"All",isChecked:false,key:"all"},
          {label:"Department",isChecked:false,key:"department"},
          {label:"Designation",isChecked:false,key:"designation"},
          {label:"Manage Employee",isChecked:false,key:"manageEmployee"},
          {label:"Add Employee",isChecked:false,key:"addEmployee"},
          {label:"Add Document",isChecked:false,key:"addDocument"},
        
      ]
  },
  {
      name:"Attendence",
      nameKey:"attendance",
      values:[
          {label:"Attendence",isChecked:false,key:"attendance"},
         
      ]
  },
  {
      name:"Leave",
      nameKey:"leave",
      values:[
          {label:"All",isChecked:false,key:"all"},
          {label:"Leave Application",isChecked:false,key:"leaveApplication"},
          {label:"Holiday",isChecked:false,key:"holiday"},
          {label:"Add Leave Type",isChecked:false,key:"addleaveType"},
         
      ]
  },
  {
      name:"Payroll",
      nameKey:"payroll",
      values:[
          {label:"All",isChecked:false,key:"all"},
          {label:"Employee Salary",isChecked:false,key:"employeeSalary"},
          {label:"Add Payroll",isChecked:false,key:"addPayroll"},
          {label:"Payroll Item",isChecked:false,key:"payrollItem"},
          {label:"Payslip",isChecked:false,key:"paySlip"},
         
      ]
  },
  {
      name:"Loan",
      nameKey:"loan",
      values:[
          {label:"All",isChecked:false,key:"all"},
          {label:"Add New Loan",isChecked:false,key:"addNewLoan"},
          {label:"Loan Report",isChecked:false,key:"loanReport"},
          {label:"Loan Approvals",isChecked:false,key:"loanApprovals"},
         
      ]
  }
   ])
   const [singleSections,setSingleSections]=useState({
       dashboard:false,
       products:false,
       enableStockAndUnitPrice:false,
       deviceSettings:false,
       generateQrBarcode:false
   })



    const designationList = useSelector((state) => state.allDesignationListSlice.value);
    const allActiveEmployeeList = useSelector(
        (state) => state.allActiveEmployeeSlice.value
      );
      //Roles and permission given for an employee or a designation
    const rolesAndPermissionsList = useSelector(
        (state) => state.viewRolesAndPermissionSlice.value
      );
    const [rolesAndPermissionType, setRolesAndPermissionType] =useState("designation");
    const [designation, setDesignation] = useState(null);
    //Filtered Employees by designation
    const [filteredEmployees, setFilteredEmployees] = useState([]);
      //Characters entered for search employee
    const [searchKeyword, setSearchKeyword] = useState("");
     //Searched Employees by characters
     const [empSearchResult, setEmpSearchResult] = useState([]);
     //Selected Employee Id
     const [selectedEmpId, setSelectedEmpId] = useState(null);
     const [snackBarStates, setSnackBarStates] = useState({
      alert: false,
      message: "",
      success: false,
      error: false,
    });

    const closeSnackbar = () => {
      setSnackBarStates({
          ...snackBarStates,
          alert: false,
          message: "",
          success: false,
          error: false,
      });
  };
    
     const changeRadioFn = (e) => {
        setRolesAndPermissionType(e.target.value);
      };

        //Get Search Keyword
     const getSearchKeyword = (e) => {
     setSearchKeyword(e.target.value);
      };

       //Filter Employees By characters
      const filterEmployeesByCharacters = (chars, empList) => {
    let searchResult = [];
    let filteredArray = [];
    if (chars !== "") {
      searchResult = empList?.filter((obj) =>
        obj?.staff_name?.toLowerCase()?.includes(chars?.toLowerCase())
      );
      filteredArray = searchResult;
    } else {
      filteredArray = empList;
    }
    return filteredArray;
      };


      //Get Checked Employee Id
      const getCheckedEmpId = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedEmpId(value);
    }
      };

      // purchase change

      const changeCheckBox=(index)=>{
          purchaseSection[index].isChecked=! purchaseSection[index].isChecked
            if (!purchaseSection[index].isChecked) {
              purchaseSection[0].isChecked = false;
            }
            if (purchaseSection.slice(1).every((item) => item.isChecked)) {
                purchaseSection[0].isChecked = true;
            }
        setPurchaseSection([...purchaseSection])
      }
      const changeAllCheckBox=()=>{
        setPurchaseSection((prevPurchaseSection) => {
            const newPurchaseSection = prevPurchaseSection.map((item) => ({
              ...item,
              isChecked: !prevPurchaseSection[0].isChecked,
            }));
            return newPurchaseSection;
          });
      }

      // inventory change
      const changeAllCheckBoxInventory=()=>{
        setInventortSection((prevPurchaseSection) => {
            const newPurchaseSection = prevPurchaseSection.map((item) => ({
              ...item,
              isChecked: !prevPurchaseSection[0].isChecked,
            }));
            return newPurchaseSection;
          });
      }
      const changeCheckBoxInventory=(index)=>{
        inventorySection[index].isChecked=! inventorySection[index].isChecked
        if (!inventorySection[index].isChecked) {
            inventorySection[0].isChecked = false;
        }
        if (inventorySection.slice(1).every((item) => item.isChecked)) {
            inventorySection[0].isChecked = true;
        }
        setInventortSection([...inventorySection])
      }

      // sales change
      const changeAllCheckBoxSales=()=>{
        setSalesSection((prevPurchaseSection) => {
            const newPurchaseSection = prevPurchaseSection.map((item) => ({
              ...item,
              isChecked: !prevPurchaseSection[0].isChecked,
            }));
            return newPurchaseSection;
          });
      }
      const changeCheckBoxSales=(index)=>{
        salesSection[index].isChecked=! salesSection[index].isChecked
        if (!salesSection[index].isChecked) {
            salesSection[0].isChecked = false;
        }
        if (salesSection.slice(1).every((item) => item.isChecked)) {
            salesSection[0].isChecked = true;
        }
        setSalesSection([...salesSection])
      }

      // pos change
      const changeAllCheckBoxPos=(sectionIndex)=>{
        const updatedPosSection = [...posSection];
        const allChecked = updatedPosSection[sectionIndex].values.every(option => option.isChecked);
        
        updatedPosSection[sectionIndex].values.forEach(option => {
          option.isChecked = !allChecked;
        });
    
        setPosSection(updatedPosSection);
      }

      const changeCheckBoxPos=(sectionIndex,objIndex)=>{
        posSection[sectionIndex].values[objIndex].isChecked=!posSection[sectionIndex].values[objIndex]?.isChecked
        
        if (!posSection[sectionIndex].values[objIndex].isChecked) {
            posSection[sectionIndex].values[0].isChecked = false;
        }
        if (posSection[sectionIndex].values.slice(1).every((item) => item.isChecked)) {
            posSection[sectionIndex].values[0].isChecked = true;
        }
        setPosSection([...posSection]) 
    }
    // customer change
    const changeAllCheckBoxCustomer=()=>{
      setCustomerSection((prevPurchaseSection) => {
          const newPurchaseSection = prevPurchaseSection.map((item) => ({
            ...item,
            isChecked: !prevPurchaseSection[0].isChecked,
          }));
          return newPurchaseSection;
        });
    }
    const changeCheckBoxCustomer=(index)=>{
      customerSection[index].isChecked=! customerSection[index].isChecked
      if (!customerSection[index].isChecked) {
        customerSection[0].isChecked = false;
      }
      if (customerSection.slice(1).every((item) => item.isChecked)) {
        customerSection[0].isChecked = true;
      }
      setCustomerSection([...customerSection])
    }
    // report change
    const changeAllCheckBoxReport=()=>{
      setReportSection((prevPurchaseSection) => {
          const newPurchaseSection = prevPurchaseSection.map((item) => ({
            ...item,
            isChecked: !prevPurchaseSection[0].isChecked,
          }));
          return newPurchaseSection;
        });
    }
    const changeCheckBoxReport=(index)=>{
      reportSection[index].isChecked=! reportSection[index].isChecked
      if (!reportSection[index].isChecked) {
        reportSection[0].isChecked = false;
      }
      if (reportSection.slice(1).every((item) => item.isChecked)) {
        reportSection[0].isChecked = true;
      }
      setReportSection([...reportSection])
    }
    // accounts change
    const changeAllCheckAccounts=(sectionIndex)=>{
      const updatedPosSection = [...accountSection];
      const allChecked = updatedPosSection[sectionIndex].values.every(option => option.isChecked);
      
      updatedPosSection[sectionIndex].values.forEach(option => {
        option.isChecked = !allChecked;
      });
  
      setAccountSection(updatedPosSection);
    }

    const changeCheckBoxAccounts=(sectionIndex,objIndex)=>{
      accountSection[sectionIndex].values[objIndex].isChecked=!accountSection[sectionIndex].values[objIndex]?.isChecked
      
      if (!accountSection[sectionIndex].values[objIndex].isChecked) {
        accountSection[sectionIndex].values[0].isChecked = false;
      }
      if (accountSection[sectionIndex].values.slice(1).every((item) => item.isChecked)) {
        accountSection[sectionIndex].values[0].isChecked = true;
      }
      setAccountSection([...accountSection]) 
  }
  // staff change
  const changeAllCheckStaff=(sectionIndex)=>{
    const updatedPosSection = [...staffSection];
    const allChecked = updatedPosSection[sectionIndex].values.every(option => option.isChecked);
    
    updatedPosSection[sectionIndex].values.forEach(option => {
      option.isChecked = !allChecked;
    });

    setStaffSection(updatedPosSection);
  }

  const changeCheckBoxStaff=(sectionIndex,objIndex)=>{
    staffSection[sectionIndex].values[objIndex].isChecked=!staffSection[sectionIndex].values[objIndex]?.isChecked
    
    if (!staffSection[sectionIndex].values[objIndex].isChecked) {
      staffSection[sectionIndex].values[0].isChecked = false;
    }
    if (staffSection[sectionIndex].values.slice(1).every((item) => item.isChecked)) {
      staffSection[sectionIndex].values[0].isChecked = true;
    }
    setStaffSection([...staffSection]) 
}
// single section change
const checkboxChangeSingleSection=(key)=>(e)=>{
     setSingleSections({...singleSections,[key]:e.target.checked})
}


    


      const purchaseSectionObject = {};
      purchaseSection.forEach(section => {
          purchaseSectionObject[section.key] = section.isChecked;
      });
      const inventorySectionObject={};
      inventorySection.forEach(section=>{
        inventorySectionObject[section.key]=section.isChecked;
      })
      const salesSectionObject={};
      salesSection?.forEach(section=>{
        salesSectionObject[section.key]=section.isChecked;
      })

      
      const posSectionObject = {};

      posSection.forEach(section => {
        const sectionObject = {};
        section.values.forEach(value => {
          sectionObject[value.key] = value.isChecked;
        });
        posSectionObject[section.nameKey] = sectionObject;
      });

      const customerSectionObject={};
      customerSection?.forEach(section=>{
        customerSectionObject[section.key]=section.isChecked;
      })
      const reportSectionObject={};
      reportSection?.forEach(section=>{
        reportSectionObject[section.key]=section.isChecked;
      })

      const accountSectionObject = {};

      accountSection.forEach(section => {
        const sectionObjectAcc = {};
        section.values.forEach(value => {
          sectionObjectAcc[value.key] = value.isChecked;
        });
        accountSectionObject[section.nameKey] = sectionObjectAcc;
      });

      const staffSectionObject = {};

      staffSection.forEach(section => {
        const sectionObjectStaf = {};
        section.values.forEach(value => {
          sectionObjectStaf[value.key] = value.isChecked;
        });
        staffSectionObject[section.nameKey] = sectionObjectStaf;
      });


      const body={
        type:rolesAndPermissionType==="designation"?1:0,
        empId:rolesAndPermissionType==="designation"?designation?._id:selectedEmpId,
        dashboard:singleSections?.dashboard,
        products:singleSections?.products,
        enableStockAndUnitPrice:singleSections?.enableStockAndUnitPrice,
        purchase:purchaseSectionObject,
        inventory:inventorySectionObject,
        sales:salesSectionObject,
        pointOfSale:posSectionObject,
        customer:customerSectionObject,
        report:reportSectionObject,
        account:accountSectionObject,
        staff:staffSectionObject,
        deviceSettings:singleSections?.deviceSettings,
        generateQrBarcode:singleSections?.generateQrBarcode
      }

      const clickRolesAndPermissionUpdateBtn=()=>{
        
        if(designation === null&&selectedEmpId===null){
          setSnackBarStates({...snackBarStates,message:"Select a Designation or Employee",alert:true})
        }
        else{
          addEmployeeRolesAndPermissionAPICall(
            body,
            setSnackBarStates,
            snackBarStates
            )
          // console.log(body);
        }
      }
    

      useEffect(()=>{
        getAllDesignationAPICall();
       
      },[])

      // filter Employees by designation id
      useEffect(()=>{
        let filteredEmployees = [];
        if (allActiveEmployeeList !== undefined) {
          filteredEmployees = allActiveEmployeeList?.filter(
            (obj) => obj?.designation === designation?._id
          );
          setFilteredEmployees(filteredEmployees)
        }
        // return filteredEmployees;
      
      },[designation])

      //Search Employees by characters
      useEffect(() => {
    if (allActiveEmployeeList !== undefined) {
      setEmpSearchResult(allActiveEmployeeList);
      let filteredArray = filterEmployeesByCharacters(
        searchKeyword,
        allActiveEmployeeList
      );
      setEmpSearchResult(filteredArray);
      console.log("filter employee fn invoked");
    }
     }, [searchKeyword, allActiveEmployeeList]);

     useEffect(()=>{
      if(rolesAndPermissionType==="designation"){
        getRolesAndPermissionAPICall({_id:designation?._id})
      }
      else if(rolesAndPermissionType==="employee"){
        getRolesAndPermissionAPICall({_id:selectedEmpId})
      }
    
     },[designation,selectedEmpId])

     useEffect(()=>{
         if(rolesAndPermissionsList !== undefined){
          setSingleSections({
            ...singleSections,
            dashboard:rolesAndPermissionsList?.dashboard,
            products:rolesAndPermissionsList?.products,
            enableStockAndUnitPrice:rolesAndPermissionsList?.enableStockAndUnitPrice,
            deviceSettings:rolesAndPermissionsList?.deviceSettings,
            generateQrBarcode:rolesAndPermissionsList?.generateQrBarcode
          })
          // purchase
          const updatedPurchaseSection = purchaseSection.map(section => ({
            ...section,
            isChecked:rolesAndPermissionsList?.purchase !== undefined && rolesAndPermissionsList?.purchase[section.key],
          }));
      
          setPurchaseSection(updatedPurchaseSection);
          // inventory
          const updatedInventorySection = inventorySection.map(section => ({
            ...section,
            isChecked: rolesAndPermissionsList?.inventory !== undefined && rolesAndPermissionsList?.inventory[section.key],
          }));
      
          setInventortSection(updatedInventorySection);
          //sales
          const updatedSalesSection = salesSection.map(section => ({
            ...section,
            isChecked: rolesAndPermissionsList?.sales !== undefined && rolesAndPermissionsList?.sales[section.key],
          }));
      
          setSalesSection(updatedSalesSection);
          // pos
          const updatedPosSection = posSection.map(section => ({
            ...section,
            values: section.values.map(value => ({
              ...value,
              isChecked: rolesAndPermissionsList.pointOfSale !== undefined && rolesAndPermissionsList.pointOfSale[section.nameKey][value.key],
            })),
          }));
      
          setPosSection(updatedPosSection);
          // customer
          const updatedCustomerSection = customerSection.map(section => ({
            ...section,
            isChecked: rolesAndPermissionsList?.customer !== undefined && rolesAndPermissionsList?.customer[section.key],
          }));
      
          setCustomerSection(updatedCustomerSection);
          // reports
          const updatedReportSection = reportSection.map(section => ({
            ...section,
            isChecked: rolesAndPermissionsList?.report !== undefined && rolesAndPermissionsList?.report[section?.key],
          }));
      
          setReportSection(updatedReportSection);
          //accounts
          const updatedAccountsSection = accountSection?.map(section => ({
            ...section,
            values: section?.values?.map(value => ({
              ...value,
              isChecked: rolesAndPermissionsList?.account !== undefined && rolesAndPermissionsList?.account?.[section?.nameKey]?.[value?.key],
            })),
          }));
      
          setAccountSection(updatedAccountsSection);
          // staff section
          const updatedStaffSection = staffSection?.map(section => ({
            ...section,
            values:section?.values?.map(value => ({
              ...value,
              isChecked:rolesAndPermissionsList?.staff?.[section?.nameKey]?.[value?.key],
            })),
          }));
      
          setStaffSection(updatedStaffSection);

         }
     },[rolesAndPermissionsList])


     console.log(rolesAndPermissionsList);
     

  return (
    <div className="roles-and-permission-container">
       <div className="roles-and-permission-left-container">
        <div className="global-radio-button roles-and-permission-radio-btn">
          <input
            style={{ margin: "0 2% 0 0" }}
            defaultChecked
            type="radio"
            id="rolesAndPermissionDesignation"
            name="rolesAndPermission"
            value="designation"
            onChange={changeRadioFn}
          />
          <label htmlFor="rolesAndPermissionDesignation">Designation</label>
          <input
            style={{ margin: "0 2% 0 4%" }}
            type="radio"
            id="rolesAndPermissionEmployee"
            name="rolesAndPermission"
            value="employee"
            onChange={changeRadioFn}
          />
          <label htmlFor="rolesAndPermissionEmployee">Employee</label>
        </div>
        {rolesAndPermissionType === "designation" ? (
          <div className="roles-and-permission-designation-container">
            <div className="roles-and-permission-designation-top-container">
              <div className='global-single-input auto-complete' style={{width:"98%"}}>
                 <Autocomplete
                 sx={{width:"100%"}}
                 options={designationList||[""]}
                 getOptionLabel={(option)=>option?.position}
                 renderInput={(params)=><TextField {...params} className='roles-and-permission-designation' placeholder='Select Designation'   />}
                 value={designation}
                 onChange={(e,newValue)=>setDesignation(newValue)}
                />
       
              </div>
            </div>
            <div className="roles-and-permission-employee-radio-btn-container">
              {(filteredEmployees?.length > 0 &&
                filteredEmployees
                  ?.slice(0)
                  ?.reverse()
                  ?.map((emp, i) => (
                    <p key={i} style={{margin:"2% 2%"}}>{`${emp?.emp_id} - ${emp?.staff_name}`}</p>
                  ))) || <p>No data</p>}
            </div>
          </div>
        ) : rolesAndPermissionType === "employee" ? (
          <div style={{height:"100%"}}>
            <div className="roles-and-permission-employee-top-container">
              <input
                type="text"
                placeholder="Search employee by name..."
                value={searchKeyword}
                onChange={getSearchKeyword}
              />
              <IconButton disabled className="roles-and-permission-search-icon">
                <SearchIcon />
              </IconButton>
            
            </div>
            <div className="roles-and-permission-employee-radio-btn-container">
              {(empSearchResult?.length > 0 &&
                empSearchResult
                  ?.slice(0)
                  ?.reverse()
                  ?.map((emp, i) => (
                    <div
                      className="roles-and-permission-employee-radio-btn"
                      key={i}
                    >
                      <input
                        type="radio"
                        id={emp?._id}
                        name="employee"
                        value={emp?._id}
                        onChange={getCheckedEmpId}
                      />
                      <label
                        htmlFor={emp?._id}
                      >{`${emp?.emp_id} - ${emp?.staff_name}`}</label>
                    </div>
                  ))) || <span>No data</span>}
            </div>
          </div>
        ) : undefined}
       </div>
       <div className="roles-and-permission-right-container">
          <div className="roles-and-permission-heading-container">
              <h3>Module Access</h3>
              <button
                 className="btn btn-primary btn-update-roles-and-permission"
                onClick={clickRolesAndPermissionUpdateBtn}
                >
            Update
          </button>
          </div>
          <hr className="global-hr" />
          <div> 
             <div className="roles-and-permission-single-input">
            <p>Dashboard</p>
            <input
              className="toggle-checkbox"
              type={"checkbox"}
              name="productList"
              checked={singleSections?.dashboard}
              onChange={checkboxChangeSingleSection("dashboard")}
            />
             </div>
          <div className="roles-and-permission-single-input">
            <p>Products</p>
            <input
              className="toggle-checkbox"
              type={"checkbox"}
              name="productList"
              checked={singleSections?.products}
              onChange={checkboxChangeSingleSection("products")}
            />
          </div>
         {
         singleSections?.products &&
         <div className="roles-and-permission-single-input">
            <p style={{fontSize:"0.8rem",color:"blue",paddingLeft:"5px",margin:"0"}}>Display cost and stock value</p>
            <input
              className="toggle-checkbox"
              type={"checkbox"}
              name="enableStockAndUnitPrice"
              checked={singleSections?.enableStockAndUnitPrice}
              onChange={checkboxChangeSingleSection("enableStockAndUnitPrice")}
            />
          </div>}
              <div className="roles-and-permission-multiple-input">
                <Accordion>
                   <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                     >
                      <p>Purchase</p>
                   </AccordionSummary>
                   <AccordionDetails>
                      {
                        purchaseSection?.map((obj,i)=>(
                            <div className="roles-and-permission-single-input accordion-multiple-input">
                               <p>{obj?.label}</p>
                               <input
                                  className="toggle-checkbox"
                                  type={"checkbox"}
                                  checked={obj?.isChecked}
                                  onChange={()=>obj?.label === "All" ? changeAllCheckBox():changeCheckBox(i)}
                                 
                                />
                            </div>
                        ))
                      }
                   </AccordionDetails>
                </Accordion>
              </div>
              <div className="roles-and-permission-multiple-input">
                <Accordion>
                   <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                     >
                      <p>Inventory</p>
                   </AccordionSummary>
                   <AccordionDetails>
                      {
                        inventorySection?.map((obj,i)=>(
                            <div className="roles-and-permission-single-input accordion-multiple-input">
                               <p>{obj?.label}</p>
                               <input
                                  className="toggle-checkbox"
                                  type={"checkbox"}
                                  checked={obj?.isChecked}
                                  onChange={()=>obj?.label === "All" ? changeAllCheckBoxInventory():changeCheckBoxInventory(i)}
                                 
                                />
                            </div>
                        ))
                      }
                   </AccordionDetails>
                </Accordion>
              </div>
              <div className="roles-and-permission-multiple-input">
                <Accordion>
                   <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                     >
                      <p>Sales</p>
                   </AccordionSummary>
                   <AccordionDetails>
                      {
                        salesSection?.map((obj,i)=>(
                            <div className="roles-and-permission-single-input accordion-multiple-input">
                               <p>{obj?.label}</p>
                               <input
                                  className="toggle-checkbox"
                                  type={"checkbox"}
                                  checked={obj?.isChecked}
                                  onChange={()=>obj?.label === "All" ? changeAllCheckBoxSales():changeCheckBoxSales(i)}
                                 
                                />
                            </div>
                        ))
                      }
                   </AccordionDetails>
                </Accordion>
              </div>
              <div className="roles-and-permission-multiple-input">
                <Accordion>
                   <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                     >
                      <p>Point Of Sales</p>
                   </AccordionSummary>
                   <AccordionDetails>
                      {
                        posSection?.map((section,sectionIndex)=>(
                          <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <p>{section?.name}</p>
                            </AccordionSummary>
                            <AccordionDetails>
                            {
                              section?.values?.map((obj,objIndex)=>(
                                <div className="roles-and-permission-single-input accordion-multiple-input">
                                 <p>{obj?.label}</p>
                                 <input
                                  className="toggle-checkbox"
                                  type={"checkbox"}
                                  checked={obj?.isChecked}
                                  onChange={()=>obj?.label === "All"?changeAllCheckBoxPos(sectionIndex):changeCheckBoxPos(sectionIndex,objIndex)}
                                   />
                                  </div>
                                  ))
                              }
                            </AccordionDetails>
                          </Accordion>
                        ))
                      }
                   </AccordionDetails>
                </Accordion>
              </div>
              <div className="roles-and-permission-multiple-input">
                <Accordion>
                   <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                     >
                      <p>Customers</p>
                   </AccordionSummary>
                   <AccordionDetails>
                      {
                        customerSection?.map((obj,i)=>(
                            <div className="roles-and-permission-single-input accordion-multiple-input">
                               <p>{obj?.label}</p>
                               <input
                                  className="toggle-checkbox"
                                  type={"checkbox"}
                                  checked={obj?.isChecked}
                                  onChange={()=>obj?.label === "All" ? changeAllCheckBoxCustomer():changeCheckBoxCustomer(i)}
                                 
                                />
                            </div>
                        ))
                      }
                   </AccordionDetails>
                </Accordion>
              </div>
              <div className="roles-and-permission-multiple-input">
                <Accordion>
                   <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                     >
                      <p>Reports</p>
                   </AccordionSummary>
                   <AccordionDetails>
                      {
                        reportSection?.map((obj,i)=>(
                            <div className="roles-and-permission-single-input accordion-multiple-input">
                               <p>{obj?.label}</p>
                               <input
                                  className="toggle-checkbox"
                                  type={"checkbox"}
                                  checked={obj?.isChecked}
                                  onChange={()=>obj?.label === "All" ? changeAllCheckBoxReport():changeCheckBoxReport(i)}
                                 
                                />
                            </div>
                        ))
                      }
                   </AccordionDetails>
                </Accordion>
              </div>
              <div className="roles-and-permission-multiple-input">
                <Accordion>
                   <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                     >
                      <p>Accounts</p>
                   </AccordionSummary>
                   <AccordionDetails>
                      {
                        accountSection?.map((section,sectionIndex)=>(
                          <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <p>{section?.name}</p>
                            </AccordionSummary>
                            <AccordionDetails>
                            {
                              section?.values?.map((obj,objIndex)=>(
                                <div className="roles-and-permission-single-input accordion-multiple-input">
                                 <p>{obj?.label}</p>
                                 <input
                                  className="toggle-checkbox"
                                  type={"checkbox"}
                                  checked={obj?.isChecked}
                                  onChange={()=>obj?.label === "All"?changeAllCheckAccounts(sectionIndex):changeCheckBoxAccounts(sectionIndex,objIndex)}
                                   />
                                  </div>
                                  ))
                              }
                            </AccordionDetails>
                          </Accordion>
                        ))
                      }
                   </AccordionDetails>
                </Accordion>
              </div>
              <div className="roles-and-permission-multiple-input">
                <Accordion>
                   <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                     >
                      <p>Staff</p>
                   </AccordionSummary>
                   <AccordionDetails>
                      {
                        staffSection?.map((section,sectionIndex)=>(
                          <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <p>{section?.name}</p>
                            </AccordionSummary>
                            <AccordionDetails>
                            {
                              section?.values?.map((obj,objIndex)=>(
                                <div className="roles-and-permission-single-input accordion-multiple-input">
                                 <p>{obj?.label}</p>
                                 <input
                                  className="toggle-checkbox"
                                  type={"checkbox"}
                                  checked={obj?.isChecked}
                                  onChange={()=>obj?.label === "All"?changeAllCheckStaff(sectionIndex):changeCheckBoxStaff(sectionIndex,objIndex)}
                                   />
                                  </div>
                                  ))
                              }
                            </AccordionDetails>
                          </Accordion>
                        ))
                      }
                   </AccordionDetails>
                </Accordion>
              </div>
               <div className="roles-and-permission-single-input">
                 <p>Device Settings</p>
                 <input
                     className="toggle-checkbox"
                     type={"checkbox"}
                     name="productList"
                     checked={singleSections?.deviceSettings}
                     onChange={checkboxChangeSingleSection("deviceSettings")}
                   />
               </div>
               <div className="roles-and-permission-single-input">
                  <p>Generate QR/barcode</p>
                   <input
                   className="toggle-checkbox"
                   type={"checkbox"}
                   name="productList"
                   checked={singleSections?.generateQrBarcode}
                   onChange={checkboxChangeSingleSection("generateQrBarcode")}
                 />
               </div>



          </div>
       </div>
       <ErrorSnackbar
                style={{ top: "2%", left: "50%", height: "20px", width: "auto",transform:"translateX(-50%)",position:"absolute" }}
                open={snackBarStates.alert}
                handleClose={closeSnackbar}
                message={snackBarStates.message}
            />

        <SuccessSnackbar
                open={snackBarStates.success}
                handleClose={closeSnackbar}
                message={snackBarStates.message}
            />
            <ErrorSnackbar
                open={snackBarStates.error}
                handleClose={closeSnackbar}
                message={snackBarStates.message}
            />
    </div>
  )
}
