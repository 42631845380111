import React from 'react'
import { useSelector } from 'react-redux';
const JournalSinglePrint = ({ tableData, formValues }) => {

    const companyProfileData = useSelector((state) => state?.companyProfileSlice?.userValue)
    const { currencyDetails } = useSelector((state) => state?.mainDashBoardSlice)
    
    return (
        <div className="print-main-div">
            <div className="first-container">
                <div className="left-side-div">
                    <img
                        src={companyProfileData?.logo}
                        alt=""
                    />
                    <h1 style={{ margin: "0", fontSize: "medium" }}>
                        {companyProfileData?.companyName}
                    </h1>
                    <p>{companyProfileData?.companyAddress}</p>
                    <p>{companyProfileData?.contactNumber}</p>
                </div>
                <div className="right-side-div" >
                    <h1 style={{ fontWeight: "600", fontSize: "medium", color: "#3378ab" }}>Journal Entry</h1>
                    <p style={{ color: "black", fontWeight: "400" }}>{formValues?.name}</p>

                </div>
            </div>
            <div className="secound-container">
                <div style={{ display: "flex", justifyContent: "space-between", width: "40%" }}>
                    <div>
                        <p>Journal</p>
                        <p>Account</p>

                    </div>
                    <div className='res-label'>
                        <p>{formValues?.journal?.journalName}</p>
                        <p>{formValues?.accountingDate}</p>

                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", width: "40%" }}>
                    <div>
                        <p>Reference</p>

                    </div>
                    <div className='res-label'>
                        <p>{formValues?.reference}</p>

                    </div>
                </div>
            </div>
            <div className='table-div'>
                <table>
                    <thead>
                        <tr>
                            <th style={{ fontWeight: "600" }}>Accounting</th>
                            <th style={{ fontWeight: "600" }}>Partner</th>
                            <th style={{ fontWeight: "600" }}>Branch</th>
                            <th style={{ fontWeight: "600" }}>Reference</th>
                            <th style={{ fontWeight: "600" }}>Debit</th>
                            <th style={{ fontWeight: "600" }}>Credit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData?.map((item, index) => (
                            <tr key={index}>
                                <td>{item?.account?.accountName}</td>
                                <td>{item?.partner?.name}</td>
                                <td>{item?.branch?.storeCode}-{item?.branch?.branchName}</td>
                                <td >{item?.reference}</td>
                                <td >{item.debit}</td>
                                <td >{item.credit}</td>
                            </tr>
                        ))}
                        <tr>
                            <td style={{ border: "0" }} colSpan={3} ></td>
                            <td style={{ border: "0", textAlign: "end", fontSize: "0.8rem", fontWeight: "600" }}>Total :</td>
                            <td style={{ border: "0", textAlign: "start" }}>{formValues?.debitTotal} {currencyDetails?.symbol}</td>
                            <td style={{ border: "0", textAlign: "start" }}>{formValues?.creditTotal} {currencyDetails?.symbol}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    )
}

export default JournalSinglePrint