import React from "react";
import { useSelector } from "react-redux";

const AccountRecievablePrint = (props) => {
  const { data, formValues } = props;
  const decimalPosition = localStorage.getItem("decimalPosition");
  const loginResponse = useSelector((state) => state.loginResponseSlice.value);
  const today = new Date();
  const formattedToday = `${today.getDate().toString().padStart(2, "0")}-${(
    today.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${today.getFullYear()}`;

  return (
    <div className="print-main-div">
      <div className="header-div">
        <div style={{ maxWidth: "100px", height: "62px" }}>
          <img
            src={loginResponse?.logo}
            alt=""
            style={{ maxWidth: "100%", height: "100%", objectFit: "contain" }}
          />
        </div>
        <div>
          <h3 style={{ fontSize: "1em", margin: "0" }}>{formValues?.label}</h3>
          <span style={{ textAlign: "center", display: "block" }}>
            {formattedToday}
          </span>
        </div>
        <div>
          {/* <h1 style={{ fontSize: "small", margin: "0" }}>companyName</h1> */}
          <p style={{ margin: "0", fontSize: "smaller" }}>
            {loginResponse?.data?.profile?.companyName}
          </p>
          <p style={{ margin: "0", fontSize: "smaller" }}>
            {loginResponse?.data?.profile?.companyAddress}
          </p>
          <p style={{ margin: "0", fontSize: "smaller" }}>
            {loginResponse?.data?.profile?.contactNumber}
          </p>
        </div>
      </div>

      <div
        className="global-report-table-container"
        style={{ overflow: "visible" }}
      >
        <table>
          <thead>
            <tr>
              <th>customer</th>
              <th style={{ width: "22%" }}>Debit</th>
              <th style={{ width: "22%" }}>Credit</th>
              <th style={{ width: "22%" }}>Balance</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td style={{ textAlignLast: "start", paddingLeft: "10px" }}>
                  {item.customerName || item.vendorName}
                </td>
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                  {item.debit.toFixed(decimalPosition)}
                </td>
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                  {item.credit.toFixed(decimalPosition)}
                </td>
                <td style={{ textAlignLast: "end", paddingRight: "10px" }}>
                  {item.balance.toFixed(decimalPosition)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="div-table-total">
          <p style={{ width: "32%" }}>Total</p>
          <p>{formValues?.totalDebit?.toFixed(decimalPosition)}</p>
          <p>{formValues?.totalCredit?.toFixed(decimalPosition)}</p>
          <p>{formValues?.totalBalance?.toFixed(decimalPosition)}</p>
        </div>
      </div>
    </div>
  );
};

export default AccountRecievablePrint;
