import React, { useEffect, useRef, useState } from 'react'
import { viewAllCurrencyUpdated } from '../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { filterObjFromList } from '../../../../Js/generalFunctions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import CustomPagination from '../../../Single Components/CustomPagination';
import { convertDateFormat } from '../../../../Js/Date';
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import { ViewCustomerCreditReportAPICall } from './CustomerCreditReportAPI';
import { LoadingForm } from '../../../Single Components/LoadingForm';
import axios from 'axios';
import ExcelJS from "exceljs";

import ReactToPrint from 'react-to-print';
import Template from "./CustomerCreditReportprint"


import { setFilterActive } from '../../../Single Components/listSlice/filterCategorySlice';
import store from '../../../../Redux/store';
import exportToExcel from '../../../Single Components/ExcelExport';


function CustomerCreditReport() {
  let navigate = useNavigate();
  let location = useLocation();
  let paymentsPrint = useRef();

  const SingleListData = useSelector(state => state.customerCreditReport.value);
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );
    const filteredData = useSelector(
      (state) => state.salesQuatationSlice.quotationFilter
    );
  const companyProfileData = useSelector((state) => state?.companyProfileSlice?.userValue)

  const [FormDataInfo, setFormDataInfo] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
    const [filteredDatas, setFilteredData] = useState({});

  const [selectedBranch, setSelectedBranch] = useState(null);
  const handleBranchSelect = (selectedBranch) => {
    setSelectedBranch(selectedBranch);

  };
  const [symbol, setSymbol] = useState(null);
  const handleDataFromChild = (data) => {
    setFormDataInfo(data);
  };

  const totalPages = 10;
  const decimalPosition = localStorage.getItem("decimalPosition");


  const handlePageChange = (page) => {

    setCurrentPage(page);
  };
  const handleFilterChange = (updatedItems) => {
    setFilteredData(updatedItems);
  };
  // Example usage:
  const handleExport = () => {
    // Define your headers
    const headers = [
      { key: 'customerName', label: 'CUSTOMERS', width: 20 },
      { key: 'customerMobile', label: 'MOBILE', width: 20 },
      { key: 'date', label: 'LAST PAID DATE', width: 25 },
      { key: 'workOrder', label: 'WORK ORDER', width: 25 },
      { key: 'alteration', label: 'ALTERATION', width: 25 },
      { key: 'billing', label: 'BILLING', width: 15 },
      { key: 'total', label: 'TOTAL', width: 15 },
      { key: 'discount', label: 'DISCOUNT', width: 15 },
      { key: 'paidAmount', label: 'PAID', width: 15 },
      { key: 'balance', label: 'BALANCE', width: 15 },
    ];

    // Make sure your data is properly structured before passing it
    const dataToExport = filteredData?.list || [];  // Provide fallback empty array

    // Call the export function
    exportToExcel({
      data: dataToExport,
      headers,
      title: 'Customer Credit Report',
      filename: 'Customer_Credit_Report.xlsx'
    });
  };

  console.log(filteredData?.list ,'...filteredData?.list ')
  const formatFilterInfo = (filters) => {
    const parts = [];

    if (filters?.toDate && filters.fromDate) {
      parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
    }
    if (filters?.branchList) {
      parts.push(
        `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
      );
    }
    if (filters?.location) {
      parts.push(`location: ${filters?.location?.name}`);
    }
    if (filters?.toLocation) {
      parts.push(`To Location: ${filters?.toLocation?.name}`);
    }
    if (filters?.status) {
      parts.push(`status: ${filters?.status}`);
    }
    if (filters?.createdBy) {
      parts.push(`createdBy: ${filters?.createdBy?.staff_name}`);
    }
    return parts; // Return the array directly
  };
  // const downloadList = () => {
  //   const workbook = new ExcelJS.Workbook();
  //   const sheet = workbook.addWorksheet("Customer Credit Report");
  //   sheet.properties.defaultRowHeight = 20;

  //   // Add header section
  //   const titleRow = sheet.addRow(["Customer Credit Report"]);
  //   titleRow.font = { size: 15, bold: true };
  //   sheet.mergeCells("A1:F1");
  //   titleRow.getCell(1).alignment = {
  //     vertical: "middle",
  //     horizontal: "center",
  //   };

  //   // Add additional header information
  //   const filterParts = formatFilterInfo(filteredDatas) || [];

  //   // Ensure filterParts is an array and iterate over it
  //   if (Array.isArray(filterParts)) {
  //     filterParts.forEach((part, index) => {
  //       const row = sheet.addRow([part]);
  //       row.font = { size: 11, bold: true };
  //       sheet.mergeCells(`A${index + 2}:F${index + 2}`);
  //       row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
  //     });
  //   }

  //   // Add an empty row between header and table
  //   sheet.addRow([]);

  //   // Header row styling
  //   const headerRow = sheet.addRow([
  //     "CUSTOMERS",
  //     "MOBILE",
  //     "LAST PAID DATE",
  //     "WORK ORDER",
  //     "ALTERATION",
  //     "BILLING",
  //     "TOTAL",
  //     "DISCOUNT",
  //     "BALANCE	",
  //   ]);
  //   const headerFill = {
  //     type: "pattern",
  //     pattern: "solid",
  //     fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
  //   };
  //   const headerFont = {
  //     size: 12,
  //     bold: true,
  //     color: { argb: "FF000000" }, // White font color
  //   };

  //   headerRow.eachCell((cell, colNumber) => {
  //     cell.fill = headerFill;
  //     cell.font = headerFont;
  //     cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
  //   });

  //   sheet.columns = [
  //     { header: "DATE", key: "date", width: 20 },
  //     { header: "INV COUNT", key: "invoiceCount", width: 20 },
  //     { header: "INV AMOUNT", key: "invAmount", width: 25 },
  //     { header: "RETURN AMOUNT", key: "returnAmount", width: 25 },
  //     { header: "TOTAL PAID", key: "total", width: 25 },
  //     { header: "Daily Sales Report", key: "amountDue", width: 15 },
  //   ];

  //   filteredData?.list?.forEach((item, index) => {
  //     const row = sheet.addRow({
  //       date: convertDateFormat(item?._id),
  //       invoiceCount: item?.invoiceCount,
  //       invAmount: item.invAmount,
  //       returnAmount: item?.returnAmount,
  //       total: item?.total,
  //       amountDue: item?.amountDue,
  //     });

  //     const rowFill = {
  //       type: "pattern",
  //       pattern: "solid",
  //       fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
  //     };

  //     row.eachCell((cell, colNumber) => {
  //       cell.fill = rowFill;
  //       cell.border = {
  //         //   top: { style: "thin", color: { argb: "FF000000" } },
  //         //   left: { style: "thin", color: { argb: "FF000000" } },
  //         bottom: { style: "thin", color: { argb: "FFedf2f9" } },
  //         //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
  //       };
  //       if (colNumber >= 1) {
  //         // Right align all columns except the first
  //         cell.alignment = { horizontal: "center" };
  //       }
  //     });
  //   });

  //   workbook.xlsx.writeBuffer().then(function (data) {
  //     const blob = new Blob([data], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     const url = window.URL.createObjectURL(blob);
  //     const anchor = document.createElement("a");
  //     anchor.href = url;
  //     anchor.download = "Daily Sales Report.xlsx";
  //     anchor.click();
  //     window.URL.revokeObjectURL(url);
  //   });
  // };
  const handleClick = (row) => {
    store.dispatch( setFilterActive(window.location.pathname))
    localStorage.setItem("SingleViewId", row?._id);
    navigate("/userdashboard/report/CustomerCreditReport/SingleView1", { state: { customerName: row?.customerName } })
  };
  // useEffect(() => {

  //   ViewCustomerCreditReportAPICall({}, setIsLoading)

  // }, []) 
  // useEffect(() => {
  //   // Check if data already exists in Redux
  //   if (!SingleListData) {
  //     // If not, call API
  //     ViewCustomerCreditReportAPICall({}, setIsLoading)

  //   }
  // }, [SingleListData]);

  useEffect(() => {
    // const source = axios.CancelToken.source();
    if (FormDataInfo.length !== 0) {
      let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData && ViewCustomerCreditReportAPICall({
        fromDate: FormDataInfo.fromDate === '' ? '' : `${FormDataInfo.fromDate} 00:00:00`,
        toDate: FormDataInfo.toDate === '' ? '' : `${FormDataInfo.toDate} 23:59:59`,
        branchList: FormDataInfo?.branchList,
        search: FormDataInfo?.search,
        balanceType: FormDataInfo?.balanceOperator === "Greater" ? 0 :
          FormDataInfo?.balanceOperator === "Greater than equal" ? 1 :
            FormDataInfo?.balanceOperator === "Less" ? 2 :
              FormDataInfo?.balanceOperator === "Less than equal" ? 3 :
                FormDataInfo?.balanceOperator === "Equal" ? 4 :
                  null,

        balance: Number(FormDataInfo?.balanceValue),
        // index: currentPage-1,
      }, setIsLoading,
        // source
      );
    }
    // return () => {
    //   source.cancel('Request canceled by cleanup');
    // };
  }, [FormDataInfo
    //  currentPage
  ]);


  useEffect(() => {
    let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
    setSymbol(currencyObj?.symbol)
  }, [companyProfileData, currencyListUpdated])

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, [])
  return (
    <div className="global-page-parent-container">
      {/* table */}
      <div className="global-white-bg-container">
        <div className='create-button-blue-container' style={{ margin: "1%", display: 'flex', justifyContent: 'flex-end', alignItems: 'end' }}>
          {FormDataInfo?.branchList?.length > 0 && (
            <div style={{ marginLeft: '1%' }}>
              <h3>Branch name:
                {selectedBranch?.storeCode || filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                -{selectedBranch?.branchName || filtereDataOptions?.filterCategorylist?.branchList?.branchName}</h3>
            </div>
          )}
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%'
          }}>
            <CategorySearchandFilter
              onData={handleDataFromChild}
              style={{ width: "100%" }}
              onBranchSelect={handleBranchSelect}
              isBalance={true}
              onFilterChange={handleFilterChange} 

            />
            {/* <IconButton
              style={{ width: "100px" }}

              className="print-icon-container">
              <i class="bi bi-printer printer-icon"></i>
            </IconButton>
            <IconButton
              style={{ width: "100px" }}
              className="download-icon-container">
              <i class="bi bi-arrow-down-circle download-icon"></i>
            </IconButton> */}

<Tooltip title="Share">
          <IconButton>
            <i class="bi bi-share" style={{ backgroundColor:"#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div" style={{ padding: "1px" }}>
              <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrint}
        />

        <Tooltip title="Download">
          <IconButton onClick={handleExport}>
            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>
          </div>

        </div>


        <div className="create-button-blue-container">
          <h3>Customer Credit Report</h3>
          {/* <CustomPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          /> */}
        </div>
        <div className="customer-receipt-table-container">
          <table>
            <thead>
              <tr>

                <th>CUSTOMERS</th>
                <th>MOBILE</th>
                <th>LAST PAID DATE</th>
                <th>WORK ORDER</th>
                <th>ALTERATION</th>
                <th>BILLING</th>
                <th>TOTAL</th>
                <th>DISCOUNT</th>
                <th>PAID</th>
                <th>BALANCE</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                [...Array(10)].map((r, i) => (
                  <tr key={i}>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={40}
                      />
                    </td>
                  </tr>
                ))
              ) : SingleListData?.report !== undefined && SingleListData?.report?.length !== 0 ? (
                SingleListData?.report
                  ?.slice(0)
                  ?.map((r, i) => (
                    <tr key={i} onClick={() => handleClick(r)} >
                      <td style={{ width: "25px" }}>{r?.customerName}</td>
                      <td>{r?.customerMobile}</td>
                      <td>{convertDateFormat(r?.date)}</td>
                      <td style={{ textAlign: 'end', paddingRight: '3%' }}>{r?.workOrder?.toFixed(decimalPosition)}</td>
                      <td>{r?.alteration}</td>
                      <td>{r?.billing}</td>
                      <td>{r?.total}</td>
                      <td>{r?.discount}</td>
                      <td>{r?.paidAmount}</td>
                      <td>{r?.balance}</td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td style={{ textAlign: 'center' }} colSpan={10}>No Data</td>
                </tr>
              )}

            </tbody>
            <tfoot >

              <tr>
                <th style={{ textAlign: 'start', fontSize: '1rem', paddingLeft: '5%' }} colSpan={3}>TOTAL</th>
                <th >{SingleListData?.total !== undefined && SingleListData?.total?.workOrder?.toFixed(decimalPosition)}</th>
                <th >{SingleListData?.total !== undefined && SingleListData?.total?.alteration?.toFixed(decimalPosition)}</th>
                <th >{SingleListData?.total !== undefined && SingleListData?.total?.total?.toFixed(decimalPosition)}</th>
                <th >{SingleListData?.total !== undefined && SingleListData?.total?.discount?.toFixed(decimalPosition)}</th>
                <th >{SingleListData?.total !== undefined && SingleListData?.total?.discount?.toFixed(decimalPosition)}</th>
                <th >{SingleListData?.total !== undefined && SingleListData?.total?.discount?.toFixed(decimalPosition)}</th>
                <th >{SingleListData?.total !== undefined && SingleListData?.total?.billing?.toFixed(decimalPosition)}</th>
              </tr>
            </tfoot>
          </table>
        </div>
        <LoadingForm loading={isLoading} />
      </div>
      <div style={{ display: "none" }}>
  <div ref={(el) => (paymentsPrint = el)}>
    <Template SingleListData={SingleListData}/>
    
  </div>
</div>
    </div>
  )
}

export default CustomerCreditReport