import React, { useEffect, useState } from 'react'
import { viewAllCurrencyUpdated } from '../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../API/Settings/Company Settings/companySettingsAPI';
import { filterObjFromList } from '../../../../Js/generalFunctions';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IconButton, Skeleton } from '@mui/material';
import CustomPagination from '../../../Single Components/CustomPagination';
import { convertDateFormat } from '../../../../Js/Date';
import CategorySearchandFilter from '../../../Single Components/CategorySearchandFilter';
import { ShiftReportAPIcall } from './ShiftReportAPI';
import axios from 'axios';
import { setFilterActive } from '../../../Single Components/listSlice/filterCategorySlice';
import store from '../../../../Redux/store';
import exportToExcel from '../../../Single Components/ExcelExport';
import ExcelJS from "exceljs";

function ShiftReport() {
    let navigate = useNavigate();
    let location = useLocation()
    const filtereDataOptions = useSelector((state) => state.listCategory);
    const currencyListUpdated = useSelector(
        (state) => state.viewUpdatedCurrency.value
    );
    const companyProfileData = useSelector((state) => state?.companyProfileSlice?.userValue)
  const filteredData = useSelector(
      (state) => state.salesQuatationSlice.quotationFilter
    );
    const SingleListData = useSelector((state) => state?.shiftReport?.shiftReportList)

    const [FormDataInfo, setFormDataInfo] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedFromDate, setSelectedFromDate] = useState(null);
    const [selectedToDate, setSelectedToDate] = useState(null);
    const [filteredDatas, setFilteredData] = useState({});
    const handleBranchSelect = (selectedBranch) => {
        setSelectedBranch(selectedBranch);

    };
    const handleFromDateSelect = (selectedFromDate) => {
        setSelectedFromDate(selectedFromDate);

    };
    const handleToDateSelect = (selectedToDate) => {
        setSelectedToDate(selectedToDate);

    };
    const [symbol, setSymbol] = useState(null);
    const handleDataFromChild = (data) => {
        setFormDataInfo(data);
    };

    const totalPages = SingleListData?.pages;
    const decimalPosition = localStorage.getItem("decimalPosition");


    const handlePageChange = (page) => {

        setCurrentPage(page);
    };
    const handleClick = (row) => {
        store.dispatch( setFilterActive(window.location.pathname))
        localStorage.setItem("SingleViewId", row?._id);
        // navigate("/userdashboard/report/shiftReport/singleView", { state: { customerName: row?.customerName } })
        navigate("/userdashboard/report/shiftReport/singleView")
    };
    const handleFilterChange = (updatedItems) => {
        setFilteredData(updatedItems);
      };
      const formatFilterInfo = (filters) => {
        const parts = [];
    
        if (filters?.toDate && filters.fromDate) {
          parts.push(`As of ${filters.fromDate} to ${filters?.toDate}`);
        }
        if (filters?.branchList) {
          parts.push(
            `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
          );
        }
        if (filters?.location) {
          parts.push(`location: ${filters?.location?.name}`);
        }
        if (filters?.toLocation) {
          parts.push(`To Location: ${filters?.toLocation?.name}`);
        }
        if (filters?.status) {
          parts.push(`status: ${filters?.status}`);
        }
        if (filters?.createdBy) {
          parts.push(`createdBy: ${filters?.createdBy?.staff_name}`);
        }
        return parts; // Return the array directly
      };
    const downloadList = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Shift Report");
    sheet.properties.defaultRowHeight = 20;

    // Add header section
    const titleRow = sheet.addRow(["Shift Report"]);
    titleRow.font = { size: 15, bold: true };
    sheet.mergeCells("A1:H1");
    titleRow.getCell(1).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Add additional header information
    const filterParts = formatFilterInfo(filteredDatas) || [];

    // Ensure filterParts is an array and iterate over it
    if (Array.isArray(filterParts)) {
      filterParts.forEach((part, index) => {
        const row = sheet.addRow([part]);
        row.font = { size: 11, bold: true };
        sheet.mergeCells(`A${index + 2}:H${index + 2}`);
        row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
      });
    }

    // Add an empty row between header and table
    sheet.addRow([]);

    // Header row styling
    const headerRow = sheet.addRow([
      "DATE",
      "SHIFT",
      "INVOICE AMOUNT",
      "RECEIVED AMOUNT",
      "CASH",
      "BANK",
      "CHEQUE",
      "BALANCE",
    ]);
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFedf2f9" }, // Use full hex code with "FF" prefix for better compatibility
    };
    const headerFont = {
      size: 12,
      bold: true,
      color: { argb: "FF000000" }, // White font color
    };

    headerRow.eachCell((cell, colNumber) => {
      cell.fill = headerFill;
      cell.font = headerFont;
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
    });

    sheet.columns = [
      { header: "DATE", key: "date", width: 20 },
      { header: "SHIFT", key: "shift", width: 20 },
      { header: "INVOICE AMOUNT", key: "invAmt", width: 25 },
      { header: "RECEIVED AMOUNT", key: "receivedAmt", width: 25 },
      { header: "CASH", key: "cash", width: 25 },
      { header: "BANK", key: "bank", width: 25 },
      { header: "CHEQUE", key: "cheque", width: 25 },
      { header: "Shift Report", key: "balance", width: 25 },
    ];

    SingleListData?.report?.forEach((item, index) => {
      const row = sheet.addRow({
        date: convertDateFormat(item?.date),
        shift: item?.shift,
        invAmt: item.invAmt,
        receivedAmt: item?.receivedAmt,
        cash: item?.cash,
        bank: item?.bank,
        cheque: item?.cheque,
        balance: item?.balance,
      });

      const rowFill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFFFF" }, // Use full hex code with "FF" prefix for better compatibility
      };

      row.eachCell((cell, colNumber) => {
        cell.fill = rowFill;
        cell.border = {
          //   top: { style: "thin", color: { argb: "FF000000" } },
          //   left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FFedf2f9" } },
          //   right: { style: "thin", color: { argb: "FFFFFFFF" } },
        };
        if (colNumber >= 1) {
          // Right align all columns except the first
          cell.alignment = { horizontal: "center" };
        }
      });
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Shift Report.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
    useEffect(() => {
        let currencyObj = filterObjFromList("_id", currencyListUpdated, "currency", companyProfileData)
        setSymbol(currencyObj?.symbol)
    }, [companyProfileData, currencyListUpdated])

    useEffect(() => {
        viewAllCurrencyUpdated({ status: 0 });
        companyProfileInfoForUser();
    }, [])
    useEffect(() => {
        
        if (FormDataInfo?.length !== 0) {
            let body = {
                fromDate: FormDataInfo?.fromDate === '' ? '' : `${FormDataInfo?.fromDate} 00:00:00`,
                toDate: FormDataInfo?.toDate === '' ? '' : `${FormDataInfo?.toDate} 23:59:59`,
                branchId: FormDataInfo?.branchList,
                search: FormDataInfo?.search,
                index: currentPage - 1
            }
            let loadData=window.location.pathname===filtereDataOptions?.filterActive
      !loadData && ShiftReportAPIcall(body, setIsLoading);
        }
        
    }, [FormDataInfo, currentPage])
    return (
        <div className="global-page-parent-container">
            {/* table */}
            <div className="global-white-bg-container">
                <div className='create-button-blue-container' style={{ margin: "1%", display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>

                    <div style={{ marginLeft: '1%' }}>
                        <h3>Shift Report</h3>
                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        //   width: '100%'
                    }}>
                        <CategorySearchandFilter
                            onData={handleDataFromChild}
                            style={{ width: "100%" }}
                            onBranchSelect={handleBranchSelect}
                            onFromDateSelect={handleFromDateSelect}
                            onToDateSelect={handleToDateSelect}
                            onFilterChange={handleFilterChange}

                        />
                        <IconButton
                            style={{ width: "100px" }}

                            className="print-icon-container">
                            <i class="bi bi-printer printer-icon"></i>
                        </IconButton>
                        <IconButton
                        onClick={downloadList}
                            style={{ width: "100px" }}
                            className="download-icon-container">
                            <i class="bi bi-arrow-down-circle download-icon"></i>
                        </IconButton>
                    </div>

                </div>



                <div className="create-button-blue-container">

                    {FormDataInfo?.branchList?.length > 0 && (
                        <h3 style={{ marginLeft: '1%' }}>
                            {selectedBranch?.storeCode || filtereDataOptions?.filterCategorylist?.branchList?.storeCode}
                            -{selectedBranch?.branchName || filtereDataOptions?.filterCategorylist?.branchList?.branchName}
                            {FormDataInfo?.fromDate?.length > 0 && FormDataInfo?.toDate?.length > 0 ? ' ' : null}
                        </h3>
                    )}
                    {FormDataInfo?.fromDate?.length > 0 && FormDataInfo?.toDate?.length > 0 && (
                        <h3>
                            {selectedFromDate?.fromDate || filtereDataOptions?.filterCategorylist?.fromDate}
                            TO {selectedToDate?.toDate || filtereDataOptions?.filterCategorylist?.toDate}
                        </h3>
                    )}


                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: '9px',
                        width: FormDataInfo?.branchList?.length ? '62%' : '100%'
                    }}>
                        <CustomPagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </div>
                <div className="customer-receipt-table-container">
                    <table>
                        <thead>
                            <tr>

                                <th>DATE</th>
                                <th>SHIFT</th>
                                <th>INVOICE AMOUNT</th>
                                <th>RECEIVED AMOUNT</th>
                                <th>CASH</th>
                                <th>BANK</th>
                                <th>CHEQUE</th>
                                <th>BALANCE</th>
                            </tr>

                        </thead>
                        <tbody>

                            {isLoading ? (
                                [...Array(10)].map((r, i) => (
                                    <tr key={i}>
                                        <td colSpan={10}>
                                            <Skeleton
                                                variant="rectangular"
                                                animation="wave"
                                                width={"100%"}
                                                height={40}
                                            />
                                        </td>
                                    </tr>
                                ))
                            ) : SingleListData?.report !== undefined && SingleListData?.report?.length !== 0 ? (
                                SingleListData?.report
                                    ?.slice(0)
                                    ?.map((r, i) => (
                                        <tr key={i} onClick={() => handleClick(r)} >

                                            <td>{convertDateFormat(r?.date)}</td>
                                            <td>{r?.shift}</td>
                                            <td>{r?.invAmt}</td>
                                            <td>{r?.receivedAmt}</td>
                                            <td>{r?.cash}</td>
                                            <td>{r?.bank}</td>
                                            <td>{r?.cheque}</td>
                                            <td>{r?.balance}</td>
                                        </tr>
                                    ))
                            ) : (
                                <tr>
                                    <td style={{ textAlign: 'center' }} colSpan={10}>No Data</td>
                                </tr>
                            )}

                        </tbody>
                        <tfoot >
                            <tr>
                                <th style={{ textAlign: 'start', fontSize: '1rem', paddingLeft: '3%' }} colSpan={2}>TOTAL</th>
                                <th >{SingleListData?.total !== undefined && SingleListData?.total?.invAmt?.toFixed(decimalPosition)}</th>
                                <th >{SingleListData?.total !== undefined && SingleListData?.total?.receivedAmt?.toFixed(decimalPosition)}</th>
                                <th >{SingleListData?.total !== undefined && SingleListData?.total?.cash?.toFixed(decimalPosition)}</th>
                                <th >{SingleListData?.total !== undefined && SingleListData?.total?.bank?.toFixed(decimalPosition)}</th>
                                <th >{SingleListData?.total !== undefined && SingleListData?.total?.cheque?.toFixed(decimalPosition)}</th>
                                <th >{SingleListData?.total !== undefined && SingleListData?.total?.balance?.toFixed(decimalPosition)}</th>

                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ShiftReport