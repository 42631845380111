import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { convertArrayToExcel, filterObjFromList } from '../../../../../Js/generalFunctions';
import { viewAllCurrencyUpdated } from '../../../../../API/Settings/Currency/CurrencyAPI';
import { companyProfileInfoForUser } from '../../../../../API/Settings/Company Settings/companySettingsAPI';
import SearchIcon from "@mui/icons-material/Search";
import { AccountRecievableAPIcall } from './partnerReportApi';
import { useNavigate } from 'react-router-dom';
import CategorySearchandFilter from '../../../../Single Components/CategorySearchandFilter';
import { getCurrentTime } from '../../../../../Js/Date';
import { IconButton, Tooltip } from '@mui/material';
import Template from "./AccountPrint"
import ReactToPrint from 'react-to-print';
import { setFilterActive } from '../../../../Single Components/listSlice/filterCategorySlice';
import store from '../../../../../Redux/store';
import ExcelJS from "exceljs" 

export const AccountRecievable = () => {
  let paymentsPrintNew = useRef();
  const filtereDataOptions = useSelector((state) => state.listCategory);
  const configurationList = useSelector(
    (state) => state.glConfigurationSlice.apiResList
);
const accountRecievable =useSelector((state)=>state?.PartnerReport.AccountRecievable)

const companyProfileData =useSelector(
  (state) => state?.companyProfileSlice?.userValue)

  let navigate = useNavigate();
  const [symbol, setSymbol] = useState(null);
  const [FormDataInfo,setFormDataInfo]=useState([])
  const [selectedFromDate, setSelectedFromDate] = useState('');
const [selectedToDate, setSelectedToDate] = useState('');
const [selectedBranch, setSelectedBranch] = useState(null);
const [filteredData, setFilteredData] = useState({});
// Update handleDataFromChild function to store selected dates
const handleDataFromChild = (data) => {
  setFormDataInfo(data);
  // Store selected dates
  setSelectedFromDate(data.fromDate);
  setSelectedToDate(data.toDate);
};
const handleBranchSelect = (selectedBranch) => {
  setSelectedBranch(selectedBranch); 

};
const initialvalue ={
  label:"Account Receivable Report",
totalBalance:0,
totalCredit:0,
totalDebit:0,

companyLogo: "",
companyName: "",
companyAddress: "",
companyContact: "",
}
  
  const [data,setData]=useState([])
  const [formValues,setFormValues]=useState(initialvalue)

useEffect(()=>{
  setFormValues({...formValues,
    totalBalance:accountRecievable?.totalBalance || 0,
    totalCredit:accountRecievable?.totalCredit || 0,
    totalDebit:accountRecievable?.totalDebit || 0,

    companyLogo: companyProfileData?.logo || "",
    companyName: companyProfileData?.companyName || "",
    companyAddress: companyProfileData?.companyAddress || "",
    companyContact: companyProfileData?.contactNumber || "",

  });
  setData(accountRecievable?.customer)
},[accountRecievable,companyProfileData])
const handleFilterChange = (updatedItems) => {
  setFilteredData(updatedItems);
};

const formatFilterInfo = (filters) => {
  const parts = [];

  if (filters?.fromDate && filters?.toDate) {
    parts.push(`As of ${filters?.fromDate} to ${filters?.toDate}`);
  }
  console.log(filters?.fromDate);

  if (filters?.branchList) {
    parts.push(
      `Branch: ${filters?.branchList?.storeCode}-${filters?.branchList?.branchName}`
    );
  }

  return parts; // Return the array directly
};
const downloadList = () => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("Account Receivable");
  sheet.properties.defaultRowHeight = 20;

  // Add header section
  const titleRow = sheet.addRow(["Account Receivable"]);
  titleRow.font = { size: 18, bold: true };
  sheet.mergeCells("A1:D1");
  titleRow.getCell(1).alignment = {
    vertical: "middle",
    horizontal: "center",
  };

  // Add additional header information
  const filterParts = formatFilterInfo(filteredData) || [];

  // Ensure filterParts is an array and iterate over it
  if (Array.isArray(filterParts)) {
    filterParts.forEach((part, index) => {
      const row = sheet.addRow([part]);
      row.font = { size: 15, bold: true };
      sheet.mergeCells(`A${index + 2}:D${index + 2}`);
      row.getCell(1).alignment = { vertical: "middle", horizontal: "center" };
    });
  }

  // Add an empty row between header and table
  sheet.addRow([]);

  // Header row styling
  const headerRow = sheet.addRow(["Customer", "Debit", "Credit", "Balance"]);
  const headerFill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FF1d1da7" }, // Use full hex code with "FF" prefix for better compatibility
  };
  const headerFont = {
    size: 15,
    bold: true,
    color: { argb: "FFFFFFFF" }, // White font color
  };

  headerRow.eachCell((cell) => {
    cell.fill = headerFill;
    cell.font = headerFont;
    cell.alignment = { vertical: "middle", horizontal: "center" }; // Ensure header text is centered
  });

  sheet.columns = [
    { header: "Customer", key: "customerName", width: 50 },
    { header: "Debit", key: "debit", width: 20 },
    { header: "Credit", key: "credit", width: 20 },
    { header: "Account Receivable", key: "balance", width: 20 },
  ];

  accountRecievable?.customer?.forEach((item) => {
    const row = sheet.addRow({
      customerName: item.customerName,
      debit: `${symbol} ${item.debit?.toFixed(2)}`,
      credit: `${symbol} ${item?.credit?.toFixed(2)}`,
      balance: `${symbol} ${item?.balance?.toFixed(2)}`,
    });

    const rowFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFC3D4F7" }, // Use full hex code with "FF" prefix for better compatibility
    };

    row.eachCell((cell, colNumber) => {
      cell.fill = rowFill;
      cell.border = {
        top: { style: "thin", color: { argb: "FFFFFFFF" } },
        left: { style: "thin", color: { argb: "FFFFFFFF" } },
        bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
        right: { style: "thin", color: { argb: "FFFFFFFF" } },
      };
      if (colNumber === 4) {
        // Assuming the balance column is the 4th column
        const value = parseFloat(item?.balance);
        if (value < 0) {
          cell.font = { color: { argb: "FFFF0000" } }; // Red color for negative values
        }
      }
    });
  });

  // Add row for totals
  const totalRow = sheet.addRow([
    "Total",
    `${symbol} ${accountRecievable?.totalDebit?.toFixed(2)}`,
    `${symbol} ${accountRecievable?.totalCredit?.toFixed(2)}`,
    `${symbol} ${accountRecievable?.totalBalance?.toFixed(2)}`,
  ]);

  totalRow.eachCell((cell) => {
    cell.font = { bold: true, color: { argb: "FFFFFFFF" } };
    cell.fill = headerFill;
    cell.border = {
      top: { style: "thin", color: { argb: "FFFFFFFF" } },
      left: { style: "thin", color: { argb: "FFFFFFFF" } },
      bottom: { style: "thin", color: { argb: "FFFFFFFF" } },
      right: { style: "thin", color: { argb: "FFFFFFFF" } },
    };
  });

  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = "Account Receivable.xlsx";
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};


  const decimalPosition = localStorage.getItem("decimalPosition");

  const currencyListUpdated = useSelector(
    (state) => state.viewUpdatedCurrency.value
  );

 


  const handleClickRow=(row)=>{
    store.dispatch( setFilterActive(window.location.pathname))
     localStorage.setItem("singleViewById",row?._id)
    //  localStorage.setItem("fromDate", `${FormDataInfo?.fromDate} ${getCurrentTime()}`);
    //  localStorage.setItem("toDate", `${FormDataInfo?.toDate} ${getCurrentTime()} `);
     localStorage.setItem("account",row?.customerName)
     localStorage.setItem("selectedFromDate", selectedFromDate);
     localStorage.setItem("selectedToDate", selectedToDate);

    // setSelectedHeading("Ledger Balance");

    navigate("/userdashboard/accounts/report/partnerReport/accountReceivable/singleView")
  };


  useEffect(() => {
    let currencyObj = filterObjFromList(
      "_id",
      currencyListUpdated,
      "currency",
      companyProfileData
    );
    console.log(currencyObj);
    setSymbol(currencyObj?.symbol);
  }, [companyProfileData, currencyListUpdated]);

  useEffect(() => {
    viewAllCurrencyUpdated({ status: 0 });
    companyProfileInfoForUser();
  }, []);

  useEffect(()=>{
   if(FormDataInfo &&FormDataInfo?.length!==0){ let body ={
      startDate: FormDataInfo?.fromDate === '' ? '' : `${FormDataInfo?.fromDate} 00:00:00`,
      endDate: FormDataInfo?.toDate === '' ? '' : `${FormDataInfo?.toDate} 23:59:59`,
      branchId: FormDataInfo?.branchList?.length === 0 ? '' : FormDataInfo?.branchList[0],
      search:FormDataInfo?.search,
  }
  let loadData=window.location.pathname===filtereDataOptions?.filterActive
  !loadData &&  AccountRecievableAPIcall(body)
}
  },[FormDataInfo])


  return (
    <div className="global-page-parent-container"  style={{margin:"0"}}>
      <div className="global-white-bg-container">
        <div className="justify-space-between" >
          <div style={{display:"flex"}}>
          <div className="sharp-arrow" style={{ width: "189Px" }}> <p>Account Receivable Report</p></div>
         <div style={{marginLeft:"19px",marginTop:"-12px"}}>
          <Tooltip title="Share">
          <IconButton>
            <i class="bi bi-share" style={{ backgroundColor: "#9797e9", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        <ReactToPrint
          trigger={() => (
            <IconButton className="printer-div" style={{ padding: "1px" }}>
              <i style={{ color: "white", backgroundColor: " #d787e7", fontSize: "medium", padding: "8px", borderRadius: "2px" }} class="bi bi-printer"></i>
            </IconButton>
          )}
          content={() => paymentsPrintNew}
        />

        <Tooltip title="Download">
          <IconButton onClick={downloadList}>
            <i class="bi bi-arrow-down-circle" style={{ backgroundColor: "rgb(255 118 133 / 69%)", color: "white", fontSize: "medium", padding: "8px", borderRadius: "2px" }}></i>
          </IconButton>
        </Tooltip>

        </div>
        </div>
          <div>
                <CategorySearchandFilter
                statusOptions={['PURCHASE ORDER','DONE','CANCEL']}
                onData={handleDataFromChild}
                onBranchSelect={handleBranchSelect}
                onFilterChange={handleFilterChange}
                />
             </div>
        </div>
        <h1 style={{margin:"1px",fontSize:"large"}}>Account Receivable Report</h1>
     
      <div className="global-report-table-container">
        <table>
          <thead>
            <tr>
              <th>customer</th>
              <th style={{width:"22%"}}>Debit</th>
              <th style={{width:"22%"}}>Credit</th>
              <th style={{width:"22%"}}>Balance</th>
            </tr>
          </thead>
          <tbody>
            {accountRecievable?.customer?.map((item,index)=>(
            <tr key={index}  onClick={()=>handleClickRow(item)}>
              <td style={{textAlignLast:"start",paddingLeft:"10px"}}>{item.customerName}</td>
              <td style={{textAlignLast:"end",paddingRight:"10px"}}>{symbol}&nbsp;{item.debit.toFixed(decimalPosition)}</td>
              <td style={{textAlignLast:"end",paddingRight:"10px"}}>{symbol}&nbsp;{item.credit.toFixed(decimalPosition)}</td>
              <td style={{textAlignLast:"end",paddingRight:"10px"}}>{symbol}&nbsp;{item.balance.toFixed(decimalPosition)}</td>
            </tr>
          ))}
          </tbody>
          <tfoot>
              <tr>
                <th>Total</th>
                <th >{symbol}&nbsp;{accountRecievable?.totalDebit?.toFixed(decimalPosition)}</th>
                <th>{symbol}&nbsp;{accountRecievable?.totalCredit?.toFixed(decimalPosition)}</th>
                <th>{symbol}&nbsp;{accountRecievable?.totalBalance?.toFixed(decimalPosition)}</th>
              </tr>
          </tfoot>
        </table>
        </div>
    </div>
    <div style={{ display: "none" }}>
  <div ref={(el) => (paymentsPrintNew = el)}>
      <Template  data={data} formValues={formValues}/> 
  </div>
</div>
    </div>

  )
}